import { ArrowUpOutlined, CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Image, Input, InputNumber, message, Row, Select, Statistic, Tooltip, Upload } from "antd";
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from "react";
import { environment } from "../../../../env/config.env";
import currency from "../../../../helpers/currency";
import Can from "../../../../security/Can";
import entrepriseService from "../../../../services/entreprise.service";

const API_URL = environment.api;
const FILES_URL = environment.serverUrl;

const Entreprise = () => {
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [updateId , setUpdateId] = useState();
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [showImag, setShowImage] = useState(true);

  const onChange = ({ fileList: newFileList  , file: uploadedfile}) => {
    setFileList(newFileList);
    if( uploadedfile?.xhr?.response === "true"){
      getEntrepriseInfo();
      setFileList([]);
      setShowImage(true);
    }
  };


  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };


  useEffect(() => {
    getEntrepriseInfo();
    return () => {
      form.resetFields();
    };
  }, []);

  const getEntrepriseInfo = async () => {
    setLoading(true);
    const response = await entrepriseService.getEntreprise();
    if (response.status === 200) {
      initUpdateForm(response.data[0]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const initUpdateForm = (data) => {
    setUpdateId(data.id);
    const image = FILES_URL+"Files/Entreprise/"+data.image;
    setImageUrl(image);
    form.setFieldsValue(data);
  };

  const addEntreprise = async (entreprise) => {
    setLoading(true);
    const response = await entrepriseService.updateEntreprise(updateId, entreprise)
    //const response = await entrepriseService.addEntreprise(entreprise)
    if (response.status === 200) message.info("Mise à jour avec success");
    else message.errorMessage();
    setEditMode(false);
    setLoading(false);
  };

  const changeEditMode = () => {
    setEditMode(true);
  };


  return (
    <>

      <Row gutter={16}>
        <Col span={6}>
          <Card title="LOGO" extra={
              showImag ?
              <Tooltip title="Changer le logo">
                <Button  onClick={()=> {setShowImage(false); }} type="primary" shape="circle" icon={<SettingOutlined />} />
              </Tooltip>
              :
              <Tooltip title="Annuler ">
              <Button  onClick={()=> {setShowImage(true); }} type="primary" shape="circle" icon={<CloseOutlined />} />
            </Tooltip>
            }>
            <div  className="text-center">
            { showImag ? 
                <Image 
                  width={"50%"} 
                  src={imageUrl} 
                  preview={false}
                /> :
                <ImgCrop rotate cropSize={{ width: "600px", height: "300px" }} >
                  <Upload
                    multiple={false}
                    action={API_URL+"/entreprise/logo"}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                  >
                    Choisir
                  </Upload>
                </ImgCrop>
            }
            </div>
          </Card>
          <br />
        </Col>
        <Col span={18}>
          <Card
            title={"Entreprise"}
            style={{ width: "100%" }}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("1_3")}
                onClick={() => changeEditMode()}
              >
                Modifier
              </Button>
            }
          >
            <Form
              disabled
              layout="vertical"
              form={form}
              onFinish={addEntreprise}
            >
              <Row>
                <Col span={16} className="px-2">
                  <Form.Item
                    label="Nom de l'entreprise"
                    name="nom"
                    rules={[
                      {
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input disabled={!editMode} size="large" />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Devise"
                    name="devise"
                    rules={[
                      {
                        message:"Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Select size="large" disabled={!editMode} >
                    {currency.map((c) => (
                      <Select.Option key={c.id} value={c.currency}>
                        {c.code}
                      </Select.Option>
                    ))}
                  </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Raison social"
                    name="raison_social"
                    rules={[
                      {
                        message:"Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input disabled={!editMode} size="large" />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Matricule Fiscale"
                    name="matricule_fiscal"
                    rules={[
                      {
                        message:"Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input disabled={!editMode} size="large" />
                  </Form.Item>
                </Col>
                <Col span={8} className="px-2">
                  <Form.Item
                    label="Numéro registre de commerce"
                    name="registre_commerce"
                    rules={[
                      {
                        message:"Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input disabled={!editMode} size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Adresse"
                    name="adresse"
                    rules={[
                      {  
                        message: "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input disabled={!editMode} size="large" />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Adresse Mail"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Format invalid!',
                      },
                      {
                        required: true,
                        message: 'champ obligatoire !',
                      },
                    ]}
                  >
                    <Input disabled={!editMode} size="large" />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Site web"
                    name="site"
                    rules={[
                      {
                        pattern:/^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g,
                        message: 'Format invalid!',
                      },
                      {
                        message: "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input disabled={!editMode} size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Téléphone"
                    name="telephone"
                    rules={[
                      {  message: "Champ obligatoire" },
                      { message: "maximum de caractère 10 !", max: 9, min: 6 },
                    ]}
                  >
                  <InputNumber
                    max={99999999999999}
                    size="large"
                    disabled={!editMode}
                    style={{ width: "100%" }}
                  />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Fax"
                    name="fax"
                    rules={[
                      {  message: "Champ obligatoire" },
                      { message: "maximum de caractère 10 !", max: 9, min: 6 },
                    ]}
                  >
                  <InputNumber
                    max={99999999999999}
                    size="large"
                    disabled={!editMode}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex flex-row-reverse">
                <Button
                  type="primary"
                  className="mx-2"
                  disabled={!editMode}
                  onClick={form.submit}
                >
                  Enregistrer
                </Button>

                <Button disabled={!editMode} onClick={() => setEditMode(false)}>
                  Annuler
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Entreprise;
