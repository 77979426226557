import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import React, { useEffect } from "react";
import { useState } from "react";
import { environment } from "./env/config.env";
import FactrueService from "./services/factureVente.service";

const url = environment.serverUrl;
function PdfViewerModal({ diractory, reference }) {
  const [facturePath, setFacturePath] = useState("");

  const loadFilesPathes = async (reference) => {
    const response = await FactrueService.findFielsPathes(reference);
    setFacturePath(
      response.data?.file_path_facture?.replace("Documents", "Files")
    );
  };

  useEffect(() => {
    loadFilesPathes(reference);
    console.log("refer:",reference);
  }, [reference]);

  return (
    <>
      {facturePath ? (
        <Viewer fileUrl={url + facturePath} />
      ) : (
        <Viewer fileUrl={url + diractory + reference + ".pdf"} />
      )}
    </>
  );
}
export default PdfViewerModal;