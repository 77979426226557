import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import unite from "../../../helpers/unite";
import Can from "../../../security/Can";
import CategorieService from "../../../services/categorie.service";
import FournisseurService from "../../../services/fournisseur.service";
import MarqueService from "../../../services/marque.service";
import ProduitService from "../../../services/produit.service";
import TvaService from "../../../services/tva.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
import AddCategorieModalForm from "../parametres/tabs/AddCategorieModalForm";
import AddMarqueModalForm from "../parametres/tabs/AddMarqueModalForm";
import AddTvaModalForm from "../parametres/tabs/AddTvaModalForm";
import AddFournisseurModalForm from "./AddFournisseurModalForm";
import groupeClientService from "../../../services/groupeClient.service";
import formuleGratuiteService from "../../../services/formuleGratuite.service";
import moment from "moment";

const Produit = () => {
  const [produitForm] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState();

  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);

  const [marque, setMarques] = useState([]);
  const [categorie, setCategories] = useState([]);
  const [fournisseur, setFournisseur] = useState([]);
  const [tva, setTvas] = useState([]);

  const [prixAchat, setPrixAchat] = useState({ ttc: 0, ht: 0 });
  const [prixVente, setPrixVente] = useState({ ttc: 0, ht: 0 });

  const [proprietereVal, setProprietreVal] = useState(true);

  const [marges, setMarges] = useState({ margeBrute: 0, margeReel: 0 });

  const [categorieModal, setCategorieModal] = useState(false);
  const [marqueModal, setMarqueModal] = useState(false);
  const [tvaModal, setTvaModal] = useState(false);
  const [fournisseurModal, setFournisseurModal] = useState(false);
  const [showDates, setShowDates] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [groupesClients, setGroupesClient] = useState([]);
  const [formuleGratuite, setFormuleGratuite] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadTvas();
      await loadMarques();
      await loadCategories();
      await loadFournisseur();
      await loadGroupesClients();
      await loadFormuleGrauite();
      await loadProduits();
    }
    fetchData();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setPrixAchat({ ttc: 0, ht: 0 });
    setPrixVente({ ttc: 0, ht: 0 });
    setMarges({ margeBrute: 0, margeReel: 0 });
    setEditMode(false);
    setUpdateId(null);
  };

  const loadProduits = async () => {
    setLoading(true);
    const response = await ProduitService.getProduitsForDashboard();
    console.log("produits is :", response.data);
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadFournisseur = async () => {
    setLoading(true);
    const response = await FournisseurService.getFournisseurs();
    if (response.status === 200 || 204) setFournisseur(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadMarques = async () => {
    setLoading(true);
    const response = await MarqueService.getActiveMarques();
    if (response.status === 200 || 204) setMarques(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadCategories = async () => {
    setLoading(true);
    const response = await CategorieService.getActiveCategories();
    if (response.status === 200 || 204) setCategories(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadTvas = async () => {
    setLoading(true);
    const response = await TvaService.getActiveTva();
    if (response.status === 200 || 204) setTvas(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const initTableData = (produits) => {
    //debugger;
    produits = formatteData(produits);
    setDataTable(produits);
  };

  const loadGroupesClients = async () => {
    setLoading(true);
    const response = await groupeClientService.getActiveGroupeClients();
    if (response.status === 200 || 204) setGroupesClient(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadFormuleGrauite = async () => {
    setLoading(true);
    const response = await formuleGratuiteService.getActiveFormuleGratuites();
    if (response.status === 200 || 204) setFormuleGratuite(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const formatteData = (produits) => {
    for (const produit of produits) {
      produit.showType = getProductType(produit.type);
      produit.uniteAchat = Number(produit.produit_achats[0]?.unite) ?? null;
      produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
      produit.htAchat = produit.produit_achats[0]?.prixHT;
      produit.showFournisseur =
        produit.produit_achats[0]?.fournisseur?.raison_sociale ??
        produit.showType + " propriétaire";
      produit.tvaAchat = produit.produit_achats[0]?.tva;
      produit.tvaVente = produit.produit_ventes[0]?.tva;
      produit.uniteVente = Number(produit.produit_ventes[0]?.unite);
      produit.ttcVente = produit.produit_ventes[0]?.prixTTC;
      produit.htVente = produit.produit_ventes[0]?.prixHT;
    }
    return produits;
  };

  const getProductType = (type) => {
    if (type === 0) return "Produit Simple";
    else if (type === 1) return "Service";
    else return "Produit Composé";
  };

  const showProduitModal = (type) => {
    console.log(" inside  showProduitModal 1",type);
    setModalType(type);

    if (type === 0) setModalTitle("Produit");
    else if (type === 1) setModalTitle("Service");
    else setModalTitle("Produit Composé");

    const composition_produits =
      produitForm.getFieldValue("composition_produits") ?? [];
    if (composition_produits.length === 0 && type === 2) {
      const placeholderData = {
        composed_from: null,
        prixUnitaireHT: null,
        Quantité: null,
        TVA: null,
        prixTotalTTC: null,
      };
      composition_produits.push(placeholderData, placeholderData);
    }

    produitForm.setFieldsValue({ composition_produits: composition_produits });

    console.log("the title is inside  showProduitModal 2",modalTitle);

    setOpenModal(true);
  };

  const updateMode = async (record) => {
    setLoading(true);
    const response = await ProduitService.getProduit(record.id);
    if (response.status === 200 || 204) setProductDataForUpdate(response.data);
    else message.error("Une erreur est survenu ! ");
    setModalTitle(record.type)
    showProduitModal(record.type);
    console.log("the title is ",modalTitle);
    setLoading(false);
  };

  const setProductDataForUpdate = (data) => {
    const produit = data;
    setOpenModal(true);
    setPrixAchat({
      ttc: produit.produit_achats[0].prixTTC,
      ht: produit.produit_achats[0].prixHT,
    });
    setPrixVente({
      ttc: produit.produit_ventes[0].prixTTC,
      ht: produit.produit_ventes[0].prixHT,
    });
    const marge_ht = Number(
      ((produit.produit_ventes[0].prixHT - produit.produit_achats[0].prixHT) /
        produit.produit_achats[0].prixHT) *
        100
    ).toFixed(2);

    setProprietreVal(produit.proprietere);
    setShowDates(produit.formuleGratuiteId === 1 ? false : true);

    const groupesClients = [];
    for (const element of produit?.produit_groupes)
      groupesClients.push(element.groupeClientId);

    produit.applicationFormuleDebut = moment(
      produit?.applicationFormuleDebut ?? new Date()
    );
    produit.applicationFormuleFin = moment(
      produit?.applicationFormuleFin ?? new Date()
    );

    produitForm.setFieldsValue(produit);
    produitForm.setFieldsValue({
      typePrix: "HT",
      fournisseurId: produit.produit_achats[0]?.fournisseurId,
      prixFournisseur: produit.produit_achats[0].prixHT,
      prixVente: produit.produit_ventes[0].prixHT,
      tvaAchat: produit.produit_achats[0].tva,
      tvaVente: produit.produit_ventes[0].tva,
      uniteAchat: produit.produit_achats[0].unite,
      uniteVente: produit.produit_ventes[0].unite,
      proprietere: produit.proprietere,
      groupesClients: groupesClients,
      marge_ht: marge_ht,
    });

    setUpdateId(produit.id);
    setEditMode(true);
  };

  const prixVenteChanged = () => {
    const produit = produitForm.getFieldsValue();
    const prixVenteInput = produit.prixVente;
    const taxe = produit.tvaVente;
    const ht = Number(prixVenteInput).toFixed(3);
    const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
    setPrixVente((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
    const marge_ht = ((prixVenteInput - prixAchat.ht) / prixAchat.ht) * 100;
    produitForm.setFieldsValue({ marge_ht: marge_ht });
    setMargesValues();
  };

  const handleCancel = () => {
    setOpenModal(false);
    clearData();
  };

  const closingModal = () => {
    loadProduits();
    setOpenModal(false);
    setLoading(false);
    clearData();
  };

  const clearData = () => {
    produitForm.resetFields();
    produitForm.setFieldsValue({ type: false });
    produitForm.setFieldsValue({ composition_produits: [] });
    setPrixAchat({ ttc: 0, ht: 0 });
    setPrixVente({ ttc: 0, ht: 0 });
    setMarges({ margeBrute: 0, margeReel: 0 });
    setEditMode(false);
    setUpdateId(null);
  };

  const ProduitColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      sorter: (a, b) => a.showType.localeCompare(b.showType),
    },
    {
      title: "Libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.libelle.localeCompare(b.libelle),
    },
    {
      title: "Référence Int",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.reference_interne.localeCompare(b.reference_interne),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.showFournisseur.localeCompare(b.showFournisseur),
    },
    {
      title: "Achat TTC",
      dataIndex: "ttcAchat",
      sorter: (a, b) => a.ttcAchat - b.ttcAchat,
    },
    {
      title: "Vente TTC",
      dataIndex: "ttcVente",
      sorter: (a, b) => a.ttcVente - b.ttcVente,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              disabled={!Can("24_3")}
              shape="circle"
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("24_7")}
              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette Produit ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const deleteItem = async (item) => {
    const response = await ProduitService.deleteProduit(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression avec success");
      loadProduits();
    } else message.error("Une erreur est survenu ! ");
  };

  const calculateAchat = () => {
    if (
      !produitForm.getFieldValue("typePrix") ||
      !produitForm.getFieldValue("prixFournisseur")
    )
      return;
    const prixType = produitForm.getFieldValue("typePrix");
    const prix = produitForm.getFieldValue("prixFournisseur");
    const taxe = produitForm.getFieldValue("tvaAchat");
    const { ttc, ht } = calculateTotaux(prixType, prix, taxe);
    setPrixAchat((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
  };

  const calculateTotaux = (type, prix, taxe) => {
    if (type === "HT") {
      const ht = Number(prix).toFixed(3);
      const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
      return { ttc, ht };
    } else {
      const ttc = Number(prix).toFixed(3);
      const ht = (ttc / (1 + taxe / 100)).toFixed(3);
      return { ttc, ht };
    }
  };
  const calculateMarges = (venteHt, achatHt) => {
    const margeBrute = (((venteHt - achatHt) / venteHt) * 100).toFixed(2);
    const margeReel = (((venteHt - achatHt) / achatHt) * 100).toFixed(2);
    return { margeBrute, margeReel };
  };
  const setProprietereValue = () => {
    setProprietreVal(produitForm.getFieldValue("proprietere"));
  };

  const openAddMarqueModal = () => {
    setOpenModal(false);
    setMarqueModal(true);
  };
  const openAddTvaModal = () => {
    setOpenModal(false);
    setTvaModal(true);
  };
  const openAddFournisseurModal = () => {
    setOpenModal(false);
    setFournisseurModal(true);
  };
  const openAddCategorieModal = () => {
    setOpenModal(false);
    setCategorieModal(true);
  };

  const onMarqueModalClose = async (action) => {
    if (action) await loadMarques();
    setMarqueModal(false);
    setOpenModal(true);
  };

  const onTvaModalClose = async (action) => {
    if (action) await loadTvas();
    setTvaModal(false);
    setOpenModal(true);
  };

  const onFournisseurModalClose = async (action) => {
    if (action) await loadFournisseur();
    setFournisseurModal(false);
    setOpenModal(true);
  };

  const onCategorieModalClose = async (action) => {
    if (action) await loadCategories();
    setCategorieModal(false);
    setOpenModal(true);
  };

  const addProduit = async (values) => {
    setLoading(true);
    var data = values;

    //debugger;

    const taxeVente = produitForm.getFieldValue("tvaVente");
    const taxeAchat = produitForm.getFieldValue("tvaAchat");

    const fournisseurId = produitForm.getFieldValue("fournisseurId");
    const produit_ventes = {
      tva: taxeVente,
      unite: data.uniteVente,
      prixTTC: prixVente.ttc,
      prixHT: prixVente.ht,
    };
    data.produit_ventes = produit_ventes;

    const produit_achats = {
      tva: taxeAchat,
      unite: data.uniteAchat,
      prixTTC: prixAchat.ttc,
      prixHT: prixAchat.ht,
      fournisseurId: fournisseurId,
    };
    data.produit_achats = produit_achats;
    data.type = modalType;

    const response = editMode
      ? await ProduitService.updateProduit(updateId, data)
      : await ProduitService.addProduit(data);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Produit ajouter avec success");
    } else message.error("Une erreur est survenu ! ");
    closingModal();
  };

  const margeChanged = () => {
    const product = produitForm.getFieldsValue();
    const prixVenteHt =
      Number(prixAchat.ht) + Number(prixAchat.ht) * (product.marge_ht / 100);
    produitForm.setFieldsValue({
      tvaVente: product.tvaAchat,
      uniteVente: product.uniteAchat,
      prixVente: Number(prixVenteHt).toFixed(3),
      VentetypePrix: "HT",
    });
    const ht = Number(prixVenteHt).toFixed(3);
    const taxe = product.tvaAchat;
    const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
    setPrixVente((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
    setMargesValues();
  };
  const setMargesValues = () => {
    const { margeBrute, margeReel } = calculateMarges(
      prixVente.ht,
      prixAchat.ht
    );
    setMarges({ margeBrute, margeReel });
  };

  const productChanged = (index) => {
    const composition_produits = produitForm.getFieldValue(
      "composition_produits"
    );
    const produitId = composition_produits[index].composed_from;
    const selectedProduct = dataTable.find((p) => p.id === produitId);
    composition_produits[index] = {
      composed_from: produitId,
      prixUnitaireHT: selectedProduct.produit_achats[0].prixHT,
      qte: 1,
      tva: selectedProduct.produit_achats[0].tva,
      prixTotalHT: selectedProduct.produit_achats[0].prixHT,
      prixTotalTTC: selectedProduct.produit_achats[0].prixTTC,
    };
    produitForm.setFieldsValue(composition_produits);
    calculatePrixAchat();
  };

  const prixChanged = (index) => {
    const composition_produits = produitForm.getFieldValue(
      "composition_produits"
    );
    const product = composition_produits[index];
    product.prixTotalHT = Number(product.prixUnitaireHT * product.qte).toFixed(
      3
    );
    product.prixTotalTTC = Number(
      Number(product.prixTotalHT) +
        Number((product.prixTotalHT * product.tva) / 100)
    ).toFixed(3);
    composition_produits[index] = product;
    produitForm.setFieldsValue(composition_produits);
    calculatePrixAchat();
  };

  const calculatePrixAchat = () => {
    const composition_produits = produitForm.getFieldValue(
      "composition_produits"
    );
    let prixHTAchat = 0;
    let prixTTCAchat = 0;
    let tva = 0;
    for (const element of composition_produits) {
      prixHTAchat += Number(element.prixTotalHT);
      prixTTCAchat += Number(element.prixTotalTTC);
      tva += element.tva;
    }
    tva = tva / composition_produits.length;
    produitForm.setFieldsValue({ tvaAchat: tva, tvaVente: tva });
    setPrixAchat((prevState) => ({
      ...prevState,
      ttc: Number(prixTTCAchat).toFixed(3),
      ht: Number(prixHTAchat).toFixed(3),
    }));
  };

  const formuleSelectChanged = () => {
    const formuleId = produitForm.getFieldValue("formuleGratuiteId");
    if (formuleId !== 1) setShowDates(true);
    else setShowDates(false);
  };

  const items = [
    {
      label: "Ajouter Un Produit",
      className: "px-5",
      key: "1",
      onClick: () => showProduitModal(0),
    },
    {
      label: "Ajouter Un Service",
      className: "px-5",
      key: "2",
      onClick: () => showProduitModal(1),
    },
    {
      label: "Ajouter Un Produit avec embalage",
      className: "px-5",
      key: "3",
      onClick: () => showProduitModal(2),
    },
  ];

  return (
    <>
      {Can("24_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Produit"}
            extra={
              <Dropdown.Button type="primary" menu={{items}}>
                Ajouter Un Produit
              </Dropdown.Button>
            }
          >
            <TableWithFiltres
              size="50"
              data={dataTable}
              columns={ProduitColumns}
            />
            <Modal
              visible={openModal}
              onOk={produitForm.submit}
              onCancel={handleCancel}
              getContainer={false}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={1100}
              title={editMode ? "Modifier le " : "Ajouter un " + modalTitle}
            >
              <Form layout="vertical" form={produitForm} onFinish={addProduit}>
                <Tabs>
                  <Tabs.TabPane tab={"Informations"} key="1">
                    <Form.Item className="hideen" name="type">
                      <Input type="text" />
                    </Form.Item>
                    <Row>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="libelle"
                          name="libelle"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Row>
                          <Col span={20} className="pl-2">
                            <Form.Item
                              label="Catégorie"
                              name="categorieId"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                },
                              ]}
                            >
                              <Select size="large">
                                {categorie.map((c) => (
                                  <Select.Option key={c.id} value={c.id}>
                                    {c.nom}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4} className="pr-2">
                            <Form.Item label=" ">
                              <Tooltip title="Ajouter une categorie">
                                <Button
                                  className="margin-05"
                                  type=""
                                  onClick={() => openAddCategorieModal()}
                                  icon={<PlusOutlined />}
                                  size={"large"}
                                />
                              </Tooltip>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Référence Interne"
                          name="reference_interne"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Code barre"
                          name="code"
                          rules={[
                            {
                              message: "Maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Row>
                          <Col span={20} className="pl-2">
                            <Form.Item
                              label="Marque"
                              name="marqueId"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <Select size="large">
                                {marque.map((m) => (
                                  <Select.Option key={m.id} value={m.id}>
                                    {m.nom}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4} className="pr-2">
                            <Form.Item label=" ">
                              <Tooltip title="Ajouter une marque">
                                <Button
                                  className="margin-05"
                                  onClick={() => openAddMarqueModal()}
                                  type=""
                                  icon={<PlusOutlined />}
                                  size={"large"}
                                />
                              </Tooltip>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Référence constructeur"
                          name="reference_constructeur"
                          rules={[
                            {
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                    </Row>
                    {modalType !== 2 ? (
                      <div>
                        <Row>
                          <Col span={24} className="p-2">
                            {modalType === 1 ? (
                              <b> Coût du service : </b>
                            ) : (
                              <b> Prix Achat : </b>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} className="px-2">
                            <Row>
                              <Col span={21} className="pl-2">
                                <Form.Item
                                  label="Tva"
                                  name="tvaAchat"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Champ obligatoire !",
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    onChange={() => calculateAchat()}
                                  >
                                    {tva.map((t) => (
                                      <Select.Option
                                        key={t.id}
                                        value={t.valeur}
                                      >
                                        {t.titre} - {t.valeur} %
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={3} className="pr-2">
                                <Form.Item label=" ">
                                  <Tooltip title="Ajouter une TVA ">
                                    <Button
                                      className="margin-05"
                                      onClick={() => openAddTvaModal()}
                                      type=""
                                      icon={<PlusOutlined />}
                                      size={"large"}
                                    />
                                  </Tooltip>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label="Unité"
                              name="uniteAchat"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                onChange={() => calculateAchat()}
                              >
                                {unite.map((u) => (
                                  <Select.Option key={u.id} value={u.id}>
                                    {u.code_fr}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label={
                                modalTitle === "Produit Simple" ? (
                                  <> Prix Fournisseur </>
                                ) : (
                                  <> Coût</>
                                )
                              }
                              name="prixFournisseur"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                style={{ width: "100%" }}
                                min={0}
                                max={99999999999}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6} className="px-2">
                            <Form.Item
                              label="Type"
                              name="typePrix"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire ",
                                },
                              ]}
                            >
                              <Radio.Group onChange={() => calculateAchat()}>
                                <Radio value={"TTC"}>TTC</Radio>
                                <Radio value={"HT"}>HT</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span={6} className="px-2">
                            <Form.Item label="Résultat:">
                              Prix HT : {prixAchat.ht}
                              <br />
                              Prix TTC : {prixAchat.ttc}
                              <br />
                              <Input type={"hidden"}></Input>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Tabs.TabPane>
                  {modalType === 2 && (
                    <Tabs.TabPane
                      tab={"Composition "}
                      key="2"
                      forceRender={true}
                    >
                      <Row>
                        <Col span={24}>
                          <b className="p-2">Composition :</b>
                        </Col>

                        <Form.List
                          name="composition_produits"
                          label="Liste des produits"
                        >
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <>
                                  <Col span={9} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "composed_from"]}
                                      label={key === 0 ? "Produit" : "Embalage"}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <Select
                                        size="large"
                                        onChange={() => productChanged(key)}
                                      >
                                        {dataTable
                                          .filter((e) =>
                                            key === 0
                                              ? e.categorieId !== 2
                                              : e.categorieId === 2
                                          )
                                          .map((prod) => (
                                            <Select.Option
                                              key={prod.id}
                                              value={prod.id}
                                            >
                                              {prod.showType} - {prod.libelle}
                                            </Select.Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "prixUnitaireHT"]}
                                      label="Prix Unitaire HT"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        onChange={() => prixChanged(key)}
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={10000000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "qte"]}
                                      label="Quantité"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        onChange={() => prixChanged(key)}
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "tva"]}
                                      label="TVA"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "prixTotalHT"]}
                                      label="Prix Total HT"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "prixTotalTTC"]}
                                      label="Prix Total TTC"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={100000000}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </Row>
                      <Col span={22} className="px-2">
                        <div className="d-flex flex-row-reverse">
                          <Form.Item label="Résultat:">
                            Prix HT : {prixAchat.ht}
                            <br />
                            Prix TTC : {prixAchat.ttc}
                            <br />
                          </Form.Item>
                        </div>
                      </Col>
                      <div className="hideen">
                        <Form.Item name="tvaAchat">
                          <Input type="text" />
                        </Form.Item>
                      </div>
                    </Tabs.TabPane>
                  )}
                  <Tabs.TabPane tab={"Prix vente"} key="3" forceRender={true}>
                    <Row>
                      <Col span={12} className="px-2 my-2">
                        <b className="px-2">Prix Vente :</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16} className="px-2">
                        <Row>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label="Marge HT"
                              name="marge_ht"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                style={{ width: "100%" }}
                                min={0}
                                max={99999999999}
                                onChange={() => margeChanged()}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label="Prix Vente HT"
                              name="prixVente"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                style={{ width: "100%" }}
                                min={0}
                                max={99999999999}
                                onChange={() => prixVenteChanged()}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="px-4">
                        <Form.Item label="Résultat:">
                          Prix HT : {prixVente.ht} <br />
                          Prix TTC : {prixVente.ttc} <br />
                          <Input type={"hidden"}></Input>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Type"
                          name="VentetypePrix"
                          style={{ visibility: "hidden" }}
                        >
                          <Radio.Group>
                            <Radio value={"TTC"}>TTC</Radio>
                            <Radio value={"HT"}>HT</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Tva"
                          name="tvaVente"
                          style={{ visibility: "hidden" }}
                        >
                          <Select size="large" disabled>
                            {tva.map((t) => (
                              <Select.Option key={t.id} value={t.valeur}>
                                {t.titre} - {t.valeur} %
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Unité"
                          name="uniteVente"
                          style={{ visibility: "hidden" }}
                        >
                          <Select
                            className="w-100"
                            size="large"
                            disabled
                            onChange={() => calculateAchat()}
                          >
                            {unite.map((u) => (
                              <Select.Option
                                key={u.id}
                                value={u.id}
                                className="w-100"
                              >
                                {u.code_fr}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={"Description"} key="4" forceRender={true}>
                    <Row>
                      <Col span={12} className="px-2">
                        <Form.Item
                          name="proprietere"
                          label="Votre entreprise est Propriétaire?"
                          rules={[
                            { required: true, message: "Champ obligatoire !" },
                          ]}
                        >
                          <Radio.Group onChange={() => setProprietereValue()}>
                            <Radio value={false}>Non</Radio>
                            <Radio value={true}>Oui</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        {!proprietereVal && (
                          <Col span={24} className="px-2">
                            <Row>
                              <Col span={21} className="pl-2">
                                <Form.Item
                                  label="Fournisseur"
                                  name="fournisseurId"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Champ obligatoire !",
                                    },
                                  ]}
                                >
                                  <Select size="large" className="w-100">
                                    {fournisseur.map((f) => (
                                      <Select.Option key={f.id} value={f.id}>
                                        {f.nom} {f.prenom}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={3} className="pr-2">
                                <Form.Item label=" ">
                                  <Tooltip title="Ajouter un fournisseur">
                                    <Button
                                      className="margin-05"
                                      onClick={() => openAddFournisseurModal()}
                                      type=""
                                      icon={<PlusOutlined />}
                                      size={"large"}
                                    />
                                  </Tooltip>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="px-2">
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              message:
                                "Champ obligatoire avec maximum de caractère 255 !",
                              max: 254,
                            },
                          ]}
                        >
                          <TextArea type="text" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={"Caracteristique de vente"}
                    key="5"
                    forceRender={true}
                  >
                    <Row>
                      <Col span={12} className="px-2 mt-2">
                        <Form.Item
                          name="groupesClients"
                          label="Le produit peut etre vendu aux groupes "
                        >
                          <Select mode="multiple" allowClear size="large">
                            {groupesClients.map((c) => (
                              <Select.Option key={c.id} value={c.id}>
                                {c.nom}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-3">
                        <Form.Item
                          label="Paiement direct uniquement"
                          name="typeVente"
                        >
                          <Radio.Group>
                            <Radio value={false}>Non</Radio>
                            <Radio value={true}>Oui</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Quantité Minium à la vente"
                          name="qteMinVente"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Quantité Maximum à la vente"
                          name="qteMaxVente"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2 mt-2">
                        <Form.Item
                          name="formuleGratuiteId"
                          label="Formule de gratuité"
                          rules={[
                            { required: true, message: "Champ obligatoire !" },
                          ]}
                        >
                          <Select
                            allowClear
                            size="large"
                            onChange={() => formuleSelectChanged()}
                          >
                            {formuleGratuite.map((c) => (
                              <Select.Option key={c.id} value={c.id}>
                                {c.nom}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {showDates && (
                        <>
                          <Col span={6} className="px-2 mt-2">
                            <Form.Item
                              label="Date debut"
                              name="applicationFormuleDebut"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder=""
                                format={""}
                                size="large"
                                className="w-100"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={6} className="px-2 mt-2">
                            <Form.Item
                              label="Date fin"
                              name="applicationFormuleFin"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder=""
                                format={""}
                                size="large"
                                className="w-100"
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Form>
            </Modal>

            <AddTvaModalForm
              showModal={tvaModal}
              tvaAdded={(action) => onTvaModalClose(action)}
            ></AddTvaModalForm>
            <AddMarqueModalForm
              showModal={marqueModal}
              marqueAdded={(action) => onMarqueModalClose(action)}
            ></AddMarqueModalForm>
            <AddCategorieModalForm
              showModal={categorieModal}
              categorieAdded={(action) => onCategorieModalClose(action)}
            ></AddCategorieModalForm>
            <AddFournisseurModalForm
              showModal={fournisseurModal}
              fournisseurAdded={(action) => onFournisseurModalClose(action)}
            ></AddFournisseurModalForm>
          </Card>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default Produit;
