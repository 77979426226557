import {
    MinusCircleOutlined
} from "@ant-design/icons";
import {
    Button, Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Radio,
    Spin,
    Switch,
    Tabs
} from "antd";
import React, { useEffect, useState } from "react";
import country from "../../../helpers/country";
import ClientService from "../../../services/client.service";
import groupeClientService from "../../../services/groupeClient.service";

const AddClientModalForm = ({ showModal, clientAdded }) => {
  const [clientForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isEntrprise, setClientType] = useState(false);
  const [switchValue, setSwitchValue] = useState("unchecked");
  const [groupesClients, setGroupesClient] = useState([]);

  useEffect(() => {
    loadGroupesClients();
  }, [showModal]);

  const handleCancel = () => {
    closingModal();
    clientAdded(false);
  };
  const closingModal = () => {
    setLoading(false);
    clientForm.resetFields();
  };

  const loadGroupesClients = async () => {
    setLoading(true);
    const response = await groupeClientService.getActiveGroupeClients();
    if (response.status === 200 || 204) setGroupesClient(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const addClient = async (values) => {
    setLoading(true);
    var data = values;
    data.type = data.type ? data.type : false;
    const response = await ClientService.addClient(values);
    if (response.status === 200 || 204) {
      message.info("Client ajouter avec success");
    } else message.error("Une erreur est survenu ! ");
    closingModal();
    clientAdded(true);
    setLoading(false);
  };
  const switchChange = () => {
      
    setClientType(clientForm.getFieldValue("type"));
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Form layout="vertical" initialValues={{ pays: "Tunisie", }} form={clientForm} onFinish={addClient}>
      <Modal
                visible={showModal}
                onOk={clientForm.submit}
                onCancel={handleCancel}
                getContainer={false}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={1100}
                title={
                  <>
                    <div className="d-flex justify-content-between ">
                      <div className="my-0">
                        Ajouter un client
                      </div>
                      <div>
                        <Form.Item
                          className="my-0 mx-5"
                          name="type"
                          valuePropName={switchValue}
                        >
                          <Switch
                            checkedChildren="Entreprise"
                            unCheckedChildren="Particulier"
                            className="px-4"
                            onChange={() => switchChange()}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                }
              >
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Informations Générales" key="1">
                    <Row>
                      {isEntrprise && (
                        <>
                          <Col span={8} className="px-2">
                            <Form.Item
                              label="Raison Sociale"
                              name="raison_sociale"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                  max: 99,
                                },
                              ]}
                            >
                              <Input size="large" type="text" maxLength={100} />
                            </Form.Item>
                          </Col>
                          <Col span={8} className="px-2">
                            <Form.Item
                              label="Matricule Fiscal"
                              name="matricule_fiscal"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                  max: 99,
                                },
                              ]}
                            >
                              <Input size="large" type="text" maxLength={100} />
                            </Form.Item>
                          </Col>
                          <Col span={8} className="px-2">
                            <Form.Item
                              label="Registre Du commerce"
                              name="registre_commerce"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                  max: 99,
                                },
                              ]}
                            >
                              <Input size="large" type="text" maxLength={100} />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Nom"
                          name="nom"
                          rules={[
                            {max :100 ,message:"Maximum de caractère 100 !",},
                            {required :true , message : "Champ obligatoire !"},
                            {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Prénom"
                          name="prenom"
                          rules={[
                            {max :100 ,message:"Maximum de caractère 100 !",},
                            {required :true , message : "Champ obligatoire !"},
                            {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="E-mail"
                          name="email"
                          rules={[
                            {
                              type: 'email',
                              message: 'Format invalid!',
                            }
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item 
                          rules={[
                            {
                              pattern:/^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g,
                              message: 'Format invalid!',
                            },
                          ]}
                          label="Site Web" 
                          name="site">
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_phone_pro"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                            },
                          ]}
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Téléphone "
                          name="telephone_pro"
                          tooltip="Maximum 13 chiffres"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 11 !",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            showCount
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999999}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_phone_per"
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Personnel"
                          name="telephone_personnel"
                          tooltip="Maximum 13 chiffres"
                        >
                          <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999999999999}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_fax"
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Fax"
                          name="telephone_fax"
                          tooltip="Maximum 13 chiffres"
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            min={0}
                            max={999999999999999999}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="px-3">
                        <Form.Item
                          label="Groupe client"
                          name="groupeClientId"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                          <Select size="large">
                            { groupesClients.map((m) => (
                                <Select.Option key={m.id} value={m.id}>
                                  {m.nom}
                                </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-3">
                        <Form.Item
                          label="Ce client est-il exonéré ?"
                          name="exoneration"
                        >
                        <Radio.Group>
                          <Radio value={false}>Non</Radio>
                          <Radio value={true}>Oui</Radio>
                        </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Adresse de facturation" key="2" forceRender={true}>
                    <b className="px-2">Adresse de facturation :</b> <br />
                    <Row>
                      <Col span={6} className="px-2 mt-2">
                        <Form.Item
                          label="Pays"
                          name="pays"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                          <Select size="large" >
                            {country.map((c) => (
                              <Select.Option  key={c.id} value={c.name}>
                                {c.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} className="px-2 mt-2">
                        <Form.Item
                          label="Ville"
                          name="ville"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2 mt-2">
                        <Form.Item
                          label="Adresse"
                          name="adresse"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <b className="p-2">Adresses des livraisons :</b>
                      </Col>

                      <Form.List
                        name="adresse_clients"
                        label="List des addresse de livraisons"
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <>
                                <Col span={6} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "pays"]}
                                    label="Pays"
                                    initialValues={"Tunisie"}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <Select size="large">
                                      {country.map((c) => (
                                        <Select.Option key={c.id} value={c.name}>
                                          {c.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={6} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "ville"]}
                                    label="Ville"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={11} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "adresse"]}
                                    label="Adresse"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={1} className="px-2 mt-2">
                                  <Form.Item label=" ">
                                    <MinusCircleOutlined
                                      size="large"
                                      onClick={() => remove(name)}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            ))}
                            <Row>
                              <Col span={12} className="px-2 mt-3">
                                <Button type="dashed" onClick={() => add()}>
                                  Ajouter une adresse
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Modal>
      </Form>
    </Spin>
  );
};
export default AddClientModalForm;
