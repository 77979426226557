import {
  CheckOutlined, DeleteOutlined, PlusCircleOutlined, PlusOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col, Divider, Form, Input, InputNumber, message, Modal, Row,
  Spin,
  Tooltip,
  Dropdown,
  DatePicker,
  Radio,
  Select,
  Tabs,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Can from "../../../security/Can";
import ClientService from "../../../services/client.service";
import EntrepriseService from "../../../services/entreprise.service";
import FactureService from "../../../services/factureVente.service";
import CategorieService from "../../../services/categorie.service";
import FournisseurService from "../../../services/fournisseur.service";
import MarqueService from "../../../services/marque.service";
import ProduitService from "../../../services/produit.service";
import groupeClientService from "../../../services/groupeClient.service";
import formuleGratuiteService from "../../../services/formuleGratuite.service";
import TvaService from "../../../services/tva.service";
import TaxeService from "../../../services/taxe.service";
import AppTable from "../../uiHelpers/AppTabe";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import unite from "../../../helpers/unite";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableGain from "../../uiHelpers/Fiches/FicheTableGain";
import FicheTableTotauxFactureVente from "../../uiHelpers/Fiches/FicheTableTotauxFactureVente";
import FicheTableTva from "../../uiHelpers/Fiches/FicheTableTva";
import AddCategorieModalForm from "../parametres/tabs/AddCategorieModalForm";
import AddMarqueModalForm from "../parametres/tabs/AddMarqueModalForm";
import AddTvaModalForm from "../parametres/tabs/AddTvaModalForm";
import AddFournisseurModalForm from "../General/AddFournisseurModalForm";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import AddProductModalForm from "../General/AddProductModalForm";
import writtenNumber from '/node_modules/written-number/lib/index';
import currencys from '../../../helpers/currency';
import TableWithFiltres from "../../uiHelpers/TableFilters";
import Text from "antd/lib/typography/Text";

writtenNumber.defaults.lang = 'fr';
  
  function FactureSimple() {
    const [productsForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [fournisseur, setFournisseur] = useState(null);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(null);
    const [entreprise, setEntreprise] = useState({});
    const [visualisationModal, setVisualisationModal] = useState(false);
    const [productsModal, setProductsModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isDarkMode, setDarkMode] = useState(false);
    const [productList, setProductList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [appliedTaxes, setListAppliedTaxes] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [gains, setGain] = useState([]);
    const [productModal , setProductModal] = useState(false);
    const [fodec , setFodec] = useState(false);
    const [writtenTotal,setWrittenTotal] = useState("");
    const [selectedCurrency,setSelectedCurrency] = useState("");

    const [modalType, setModalType] = useState();
    const [produitForm] = Form.useForm();
    const [modalTitle, setModalTitle] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [prixAchat, setPrixAchat] = useState({ ttc: 0, ht: 0 });
    const [prixVente, setPrixVente] = useState({ ttc: 0, ht: 0 });
    const [tva, setTvas] = useState([]);
    const [marque, setMarques] = useState([]);
    const [categorie, setCategories] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [proprietereVal, setProprietreVal] = useState(true);
    const [marges, setMarges] = useState({ margeBrute: 0, margeReel: 0 });
    const [marqueModal, setMarqueModal] = useState(false);
    const [categorieModal, setCategorieModal] = useState(false);
    const [tvaModal, setTvaModal] = useState(false);
    const [fournisseurModal, setFournisseurModal] = useState(false);
    const [groupesClients, setGroupesClient] = useState([]);
    const [showDates, setShowDates] = useState(false);
    const [formuleGratuite, setFormuleGratuite] = useState([]);


    const history = useHistory();
  
    const location = useLocation();
  
    const [prixTotal, setPrixTotal] = useState({
      montant_total: 0,
      total_ttc: 0,
      total_ht: 0,
      fodec: 0,
      tva: 0,
      totalTimbreFiscal: "0.600",
    });
  
    const isVente = true;
  
    useEffect(() => {
      loadProduits();
      loadClients();
      loadEntreprise();
      getThemeMode();
      setUpdate();

      async function fetchData() {
        await loadTvas();
        await loadMarques();
        await loadCategories();
        await loadFournisseur();
        await loadGroupesClients();
        await loadFormuleGrauite();
      }
      fetchData();

      return () => {
        clearStates();
        clearAddProductState()
      };
    }, []);

    const clearAddProductState= () => {
      setDataTable([]);
      setPrixAchat({ ttc: 0, ht: 0 });
      setPrixVente({ ttc: 0, ht: 0 });
      setMarges({ margeBrute: 0, margeReel: 0 });
      setEditMode(false);
      setUpdateId(null);
    }

    const loadTaxeFodec = async () => {
      const response = await TaxeService.checkTaxeFodec();
      setFodec(response.data);
    }
  
    const setUpdate = async () => {
      if( location.state?.id ){
        const id = location.state?.id;
        setLoading(true);
        await FactureService.getFacture( id , ( response )=> {
          if (response.status === 200 || 204) {
            const activeFodec = response.data.fodec > 0 ? true : false; 
            setFodec(activeFodec)
            const clientId = response.data.produit_facture_ventes[0].clientId;
            const productList = {...response.data }
            productList.date = moment(productList.date);
            productList.client = clientId;
            productsForm.setFieldsValue(productList);
            loadProduits();
            calculeTotalTaxes(activeFodec);
            setSelectedCurrency(response.data.devise);
            const ids = []
            for (const element of productList.produit_facture_ventes) ids.push(element.produitId);
            setSelectedIds(ids);
            setUpdateId(id);
            clientChanged();
            setEditMode(true);
          }else message.error("Une erreur est survenu ! ");
          setLoading(false);
        });
      }else{
        await loadTaxeFodec()
        generateReference();
      }
    }
    const generateReference = async () => {
      await FactureService.getLast( ( response )=> {
        if (response.status === 200 || 204) {
          if(response.data.length === 0){
            var nbr  = "1";
            var reference = "FV-" + nbr.padStart(4, '0');
          }else{
            const lastRef = response.data.reference;
            const refNbr = (Number(lastRef.substring(3, 7)) + 1 ) + "";
            var reference = "FV-" + refNbr.padStart(4, '0');
          }
          productsForm.setFieldsValue({reference: reference ,date : moment(new Date()) } )
        }else message.error("Une erreur est survenu ! ");
        setLoading(false);
      });
    }
  
    const loadProduits = async () => {
      setLoading(true);
      const response = await ProduitService.getProduitsMainStore();
      if (response.status === 200 || 204) initTableData(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const getProducts = async () => {
      const response = await ProduitService.getProduitsMainStore();
      if (response.status === 200 || 204) return response.data;
      else message.error("Une erreur est survenu ! ");
    };
    
    const initTableData = (produits) => {
      produits = formatteProductsToDataTable(produits);
      console.log("the product list is:",produits);
      setProductList(produits);
    };
  
    const formatteProductsToDataTable = (produits) => {
      for (const produit of produits) {
        produit.showType = produit.type === 2 ? "Produit avec embalage" : "Produit";
        produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
        produit.htAchat = produit.produit_achats[0]?.prixHT;
        produit.pqte = !produit.type ? produit.stocks[0]?.quantite : "";
        produit.fournisseurId = produit.produit_achats[0]?.fournisseurId;
        produit.showFournisseur = produit.produit_achats[0]?.fournisseur?.raison_sociale ?? "Produit proprietere";
        produit.ttcVente = produit.produit_ventes[0].prixTTC;
        produit.htVente = produit.produit_ventes[0].prixHT;
      }
      return produits;
    };
  
    const selectProduct =  (produit) => {
      setSelectedIds((prev) => [...prev, produit.id]);      
      formatteProduct(produit);
    };
  
    const removeElement = (produitId) => {
      const list = selectedIds;
      setSelectedIds(list.filter((idP) => idP !== produitId));
    };
  
    const isSelected = (id) => {
      return selectedIds.includes(id);
    };
  
    const formatteProduct = (produit) => {
      const qte = produit?.quantite ?? 1;
      const quantiteGratuite = produit?.quantiteGratuite ?? 0;
      const quantiteTotal = produit?.quantiteTotal ?? 1;
      const prixUniteHt = produit?.produit_ventes[0]?.prixHT;
      const prixUniteTTC = produit?.produit_ventes[0]?.prixTTC;
      const tva = produit?.produit_ventes[0]?.tva;
      const ht = produit?.produit_ventes[0]?.total_prix_HT ?? Number(prixUniteHt) * Number(qte);
      const ttc = produit?.produit_ventes[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);
      const remise = produit?.remise ?? 0;
      const product = {
        produitId: produit?.id,
        libelle: produit?.libelle,
        quantite: Number(qte),
        quantiteGratuite: Number(quantiteGratuite),
        quantiteTotal: Number(quantiteTotal),
        prixHT: Number(prixUniteHt),
        prixTTC: Number(prixUniteTTC),
        tva: Number(tva),
        remise: Number(remise),
        total_prix_HT: Number(ht).toFixed(3),
        total_prix_TTC: Number(ttc).toFixed(3),
      };
      const productList = productsForm.getFieldValue("produit_facture_ventes")
        ? productsForm.getFieldValue("produit_facture_ventes")
        : [];
      productList.push(product);
      productsForm.setFieldsValue({ produit_facture_ventes: productList });
      calculeTotalTaxes();
    };
  
    const getThemeMode = () => {
      const theme = localStorage.getItem("COLOR_THEME");
      const darkMode = theme === "DARK" ? true : false;
      setDarkMode(darkMode);
    };
  
    //dont display client with groupeClientId=null 
    const loadClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        const clients = response.data.filter((it)=> it.groupeClientId !== null )
        setClients(clients);
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const getClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        return response.data;
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    
  
    const loadEntreprise = async () => {
      setLoading(true);
      const response = await EntrepriseService.getEntreprise();
      if (response.status === 200 || 204) {
        setEntreprise(response.data);
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
  
    const clearStates = () => {
      setEditMode(false);
      setUpdateId(null);
    };
  
    const handleCancel = () => {
      setProductsModal(false);
    };
  
  
    const checkIfIsFormuleGratuite = ( produit ) =>  {
      console.log("etat is", produit?.formule_gratuite.etat === 1 )
      console.log("is date between",
        moment(new Date()).isBetween( 
          moment( produit.applicationFormuleDebut ),
          moment( produit.applicationFormuleFin )
        )
      )

      if( 
        produit?.formule_gratuite.etat === 1 && 
        moment(new Date()).isBetween( 
          moment( produit.applicationFormuleDebut ),
          moment( produit.applicationFormuleFin )
        )
      ) return true;
      else return false;
    }


    const priceChanged = (index) => {
      const products = productsForm.getFieldValue("produit_facture_ventes");
      const product = products[index];
      console.log(product);
      const dbProduct = productList.find( (e)=> e.id === product.produitId );
      console.log(dbProduct);
      const applicateFormule = checkIfIsFormuleGratuite(dbProduct);

      const quantite = product.quantite;

      console.log(Math.floor( quantite / dbProduct.formule_gratuite.nombreAchete));
      console.log(Math.floor( quantite / dbProduct.formule_gratuite.nombreGratuit));
      
      
      const quantiteGratuite =  applicateFormule ? 
          Math.floor( quantite / dbProduct.formule_gratuite.nombreAchete) * dbProduct.formule_gratuite.nombreGratuit :
          product.quantiteGratuite;
      
      const taxe = product.tva;

      const prixHT = Number(product.prixHT - product.prixHT * (product.remise / 100)).toFixed(3);
      const prixTTC = (Number(prixHT) + Number((prixHT / 100) * taxe)).toFixed(3);
      const total_ht = Number(prixHT * quantite).toFixed(3);
      const total_ttc = Number(prixTTC * quantite).toFixed(3);
  
      products[index].total_prix_TTC = total_ttc;
      products[index].total_prix_HT = total_ht;
      products[index].quantiteGratuite = quantiteGratuite;
      products[index].quantiteTotal = quantiteGratuite + quantite;

      productsForm.setFieldsValue({ products });
  
      calculeTotalTaxes();
      calculeQte();
  
    };

    const calculeQte = () => {
      console.log("called");
    }
  
    const calculeTotalTaxes = async (activeFodec) => {
      const products = productsForm.getFieldValue("produit_facture_ventes");
      const list = [];
      const listGain = [];
      const isFodecActive = activeFodec ? activeFodec : fodec
      for (const product of products) {
        if (product.tva > 0.1) {
          const base = isFodecActive ? 
            Number(product.total_prix_HT) + Number(product.total_prix_HT * 1) / 100 :
            Number(product.total_prix_HT);
          const montantTaxes = isFodecActive ?
            ((Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100)).toFixed(3) :
            (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(3);
          const taxe = {
            tvaPorucentage: product.tva,
            montantTaxes: montantTaxes,
            base: base,
          };
          list.push(taxe);
        }
        const listProd = productList.length ? productList : await getProducts(); 
        const prod = listProd.find( prod => prod.id === product.produitId);
        const gain_ht = ( product.total_prix_HT * product.quantite  - (prod?.produit_achats[0]?.prixHT * product.quantite)   ).toFixed(3);
        const gain_ttc = (product.prixTTC - prod?.produit_achats[0]?.prixTTC ) * product.quantite;
        const gain = {gain_ht , gain_ttc};
        listGain.push(gain);
      }
      setGain(listGain)
  
      setListAppliedTaxes(list);
      calculateTotal(isFodecActive);
    };
  
  
    const clientChanged = async () => {
      const clientId = productsForm.getFieldValue("client");
      const clientList = clients.length ? clients : await getClients();
      const client =  clientList.find((client) => client.id === clientId);

      console.log( client );
      const products = productList.filter( (e) => getGroupProducts( client.groupeClientId , e?.produit_groupes ?? [] ) )
      console.log("the products are", products );
      setProductList(products)
      setClient( client );
    };

    const getGroupProducts = ( groupeId , productGroups ) => {
      return productGroups.find( (e) => e.groupeClientId === groupeId)
    }

    const calculateTotal = (activeFodec) => {
      const isFodecActive = activeFodec ? activeFodec : fodec
      const products = productsForm.getFieldValue("produit_facture_ventes");
      const remiseGlobal = productsForm.getFieldValue("remise_global") ? productsForm.getFieldValue("remise_global") : 0;
      let total_ht = 0;
      let tva = 0;
      let totalTimbreFiscal = 0.6;
      let total_ttc = 0;
  
      for (const product of products) {
        const montantTaxes = isFodecActive ? 
          (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) :
          (Number(product.total_prix_HT) ) * (product.tva / 100) ;
        total_ht += Number(product.total_prix_HT);
        tva += montantTaxes;
      }
      total_ht = Number(total_ht) - ( Number(total_ht) *  (Number(remiseGlobal) / 100))
  
      let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
      total_ttc += Number(total_ht) + Number(tva) + Number(fodecMontant);
      let montant_total = (Number(total_ttc) + Number(totalTimbreFiscal)).toFixed(3);
      const total = { montant_total, total_ttc, total_ht, fodec: fodecMontant, tva, totalTimbreFiscal };
      setPrixTotal(total);

      const final = montant_total.toString().split(".");
      const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
      setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);
  
    };
  
    const getProductId = (index, name) => {
      return productsForm.getFieldValue("produit_facture_ventes")[index]?.produitId;
    };
  
    const addFactureVente = async () => {
      setLoading(true);
      const product = productsForm.getFieldsValue();
      for (const element of product.produit_facture_ventes) {
        element.clientId = productsForm.getFieldValue("client");
      }
      const facutre = {...product , ...prixTotal }
      facutre.etat = 0;
      facutre.remise_global =  facutre.remise_global ? facutre.remise_global : 0;
      const response = editMode ? 
        await FactureService.updateFacture(updateId,facutre) 
        : await FactureService.addFacture(facutre);
      if (response.status === 200 || 204) {
        if(editMode) message.info("Mise à jour avec success");
        else message.info("Facture Ajouter Avec Success");
        history.push("/s/factures");
  
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    }
  
    const ProduitColumns = [
      {
        title: "Type",
        dataIndex: "showType",
        key: "1",
        sorter: (a, b) => a.type.localeCompare(b.type),
      },
      {
        title: "Libelle",
        dataIndex: "libelle",
        sorter: (a, b) => a.showNom.localeCompare(b.showNom),
      },
      {
        title: "Référence Int",
        dataIndex: "reference_interne",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: "Fournisseur",
        dataIndex: "showFournisseur",
        sorter: (a, b) => a.valeur - b.valeur,
      },
      {
        title: "Quantité",
        dataIndex: "pqte",
        sorter: (a, b) => a.pqte - b.pqte,
      },
      {
        title: "Achat TTC",
        dataIndex: "ttcAchat",
        sorter: (a, b) => a.valeur - b.valeur,
      },
      {
        title: "Vente TTC",
        dataIndex: "ttcVente",
        sorter: (a, b) => a.valeur - b.valeur,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Choisir">
              <Button
                disabled={isSelected(record.id)}
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => selectProduct(record)}
                icon={<CheckOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
    const reloadClients = async () => {
      setLoading(true);
      const response = await ClientService.getClients();
      if (response.status === 200 || 204) {
        setClients(response.data);
      } else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const openProductModal = () => {
      setProductModal(true);
    };
    const onCloseProductModal = async (action) => {
      if (action) {
        const fournisseurId = productsForm.getFieldValue("fournisseur");
        await loadProduits(fournisseurId);
      }
      setProductModal(false);
    };
  
    const changeCurrency = () => {
      const currency = productsForm.getFieldValue("devise");
      setSelectedCurrency(currency);
    }
    const disableForm = () => {
      return selectedIds.length === 0;
    }

    const getLibelle = (index) => {
      return productsForm.getFieldValue("produit_facture_ventes")[index]?.libelle;
    };

    const getProduitMin = ( index ) =>{    
      const products = productsForm.getFieldValue("produit_facture_ventes");
      const product = products[index];
      const dbProduct = productList.find( (e)=> e.id === product.produitId );
      return dbProduct ? dbProduct.qteMinVente : 0
    } 

    const getProduitMax = ( index ) =>{
      const products = productsForm.getFieldValue("produit_facture_ventes");
      const product = products[index];
      const dbProduct = productList.find( (e)=> e.id === product.produitId );
      return dbProduct ? dbProduct.qteMaxVente : 0
    } 

    const loadGroupesClients = async () => {
      setLoading(true);
      const response = await groupeClientService.getActiveGroupeClients();
      if (response.status === 200 || 204) setGroupesClient(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
  
    const loadFormuleGrauite = async () => {
      setLoading(true);
      const response = await formuleGratuiteService.getActiveFormuleGratuites();
      if (response.status === 200 || 204) setFormuleGratuite(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };

    const loadFournisseur = async () => {
      setLoading(true);
      const response = await FournisseurService.getFournisseurs();
      if (response.status === 200 || 204) setFournisseur(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const loadMarques = async () => {
      setLoading(true);
      const response = await MarqueService.getActiveMarques();
      if (response.status === 200 || 204) setMarques(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
  
    const loadCategories = async () => {
      setLoading(true);
      const response = await CategorieService.getActiveCategories();
      if (response.status === 200 || 204) setCategories(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const loadTvas = async () => {
      setLoading(true);
      const response = await TvaService.getActiveTva();
      if (response.status === 200 || 204) setTvas(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };

    const onFournisseurModalClose = async (action) => {
      if (action) await loadFournisseur();
      setFournisseurModal(false);
      setOpenModal(true);
    };

    const onCategorieModalClose = async (action) => {
      if (action) await loadCategories();
      setCategorieModal(false);
      setOpenModal(true);
    };

    const onMarqueModalClose = async (action) => {
      if (action) await loadMarques();
      setMarqueModal(false);
      setOpenModal(true);
     };

    const onTvaModalClose = async (action) => {
      if (action) await loadTvas();
      setTvaModal(false);
      setOpenModal(true);
    };

    const handleCancelAddProduct = () => {
      setOpenModal(false);
      clearData();
    }

    const formuleSelectChanged = () => {
      const formuleId = produitForm.getFieldValue("formuleGratuiteId");
      if (formuleId !== 1) setShowDates(true);
      else setShowDates(false);
    };
    
    const openAddFournisseurModal = () => {
      setOpenModal(false);
      setFournisseurModal(true);
    };

    const prixVenteChanged = () => {
      const produit = produitForm.getFieldsValue();
      const prixVenteInput = produit.prixVente;
      const taxe = produit.tvaVente;
      const ht = Number(prixVenteInput).toFixed(3);
      const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
      setPrixVente((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
      const marge_ht = ((prixVenteInput - prixAchat.ht) / prixAchat.ht) * 100;
      produitForm.setFieldsValue({ marge_ht: marge_ht });
      setMargesValues();
    };

    const calculateMarges = (venteHt, achatHt) => {
      const margeBrute = (((venteHt - achatHt) / venteHt) * 100).toFixed(2);
      const margeReel = (((venteHt - achatHt) / achatHt) * 100).toFixed(2);
      return { margeBrute, margeReel };
    };

    const setMargesValues = () => {
      const { margeBrute, margeReel } = calculateMarges(
        prixVente.ht,
        prixAchat.ht
      );
      setMarges({ margeBrute, margeReel });
    };

    const margeChanged = () => {
      const product = produitForm.getFieldsValue();
      const prixVenteHt =
        Number(prixAchat.ht) + Number(prixAchat.ht) * (product.marge_ht / 100);
      produitForm.setFieldsValue({
        tvaVente: product.tvaAchat,
        uniteVente: product.uniteAchat,
        prixVente: Number(prixVenteHt).toFixed(3),
        VentetypePrix: "HT",
      });
      const ht = Number(prixVenteHt).toFixed(3);
      const taxe = product.tvaAchat;
      const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
      setPrixVente((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
      setMargesValues();
    };

    const productChanged = (index) => {
      const composition_produits = produitForm.getFieldValue(
        "composition_produits"
      );
      const produitId = composition_produits[index].composed_from;
      const selectedProduct = dataTable.find((p) => p.id === produitId);
      composition_produits[index] = {
        composed_from: produitId,
        prixUnitaireHT: selectedProduct.produit_achats[0].prixHT,
        qte: 1,
        tva: selectedProduct.produit_achats[0].tva,
        prixTotalHT: selectedProduct.produit_achats[0].prixHT,
        prixTotalTTC: selectedProduct.produit_achats[0].prixTTC,
      };
      produitForm.setFieldsValue(composition_produits);
      calculatePrixAchat();
    };

    const calculateTotaux = (type, prix, taxe) => {
      if (type === "HT") {
        const ht = Number(prix).toFixed(3);
        const ttc = (Number(ht) + (ht / 100) * taxe).toFixed(3);
        return { ttc, ht };
      } else {
        const ttc = Number(prix).toFixed(3);
        const ht = (ttc / (1 + taxe / 100)).toFixed(3);
        return { ttc, ht };
      }
    };

    const calculateAchat = () => {
      if (
        !produitForm.getFieldValue("typePrix") ||
        !produitForm.getFieldValue("prixFournisseur")
      )
        return;
      const prixType = produitForm.getFieldValue("typePrix");
      const prix = produitForm.getFieldValue("prixFournisseur");
      const taxe = produitForm.getFieldValue("tvaAchat");
      const { ttc, ht } = calculateTotaux(prixType, prix, taxe);
      setPrixAchat((prevState) => ({ ...prevState, ttc: ttc, ht: ht }));
    };

    const openAddTvaModal = () => {
      setOpenModal(false);
      setTvaModal(true);
    };

    const openAddMarqueModal = () => {
      setOpenModal(false);
      setMarqueModal(true);
    };

    const openAddCategorieModal = () => {
      setOpenModal(false);
      setCategorieModal(true);
    };

    const calculatePrixAchat = () => {
      const composition_produits = produitForm.getFieldValue(
        "composition_produits"
      );
      let prixHTAchat = 0;
      let prixTTCAchat = 0;
      let tva = 0;
      for (const element of composition_produits) {
        prixHTAchat += Number(element.prixTotalHT);
        prixTTCAchat += Number(element.prixTotalTTC);
        tva += element.tva;
      }
      tva = tva / composition_produits.length;
      produitForm.setFieldsValue({ tvaAchat: tva, tvaVente: tva });
      setPrixAchat((prevState) => ({
        ...prevState,
        ttc: Number(prixTTCAchat).toFixed(3),
        ht: Number(prixHTAchat).toFixed(3),
      }));
    };

    const setProprietereValue = () => {
      setProprietreVal(produitForm.getFieldValue("proprietere"));
    };

    const prixChanged = (index) => {
      const composition_produits = produitForm.getFieldValue(
        "composition_produits"
      );
      const product = composition_produits[index];
      product.prixTotalHT = Number(product.prixUnitaireHT * product.qte).toFixed(
        3
      );
      product.prixTotalTTC = Number(
        Number(product.prixTotalHT) +
          Number((product.prixTotalHT * product.tva) / 100)
      ).toFixed(3);
      composition_produits[index] = product;
      produitForm.setFieldsValue(composition_produits);
      calculatePrixAchat();
    };

    const clearData = () => {
      produitForm.resetFields();
      produitForm.setFieldsValue({ type: false });
      produitForm.setFieldsValue({ composition_produits: [] });
      setPrixAchat({ ttc: 0, ht: 0 });
      setPrixVente({ ttc: 0, ht: 0 });
      setMarges({ margeBrute: 0, margeReel: 0 });
      setEditMode(false);
      setUpdateId(null);
    };

    const closingModal = () => {
      loadProduits();
      setOpenModal(false);
      setLoading(false);
      clearData();
    };

    const addProduit = async (values) => {
      setLoading(true);
      var data = values;
  
      //debugger;
  
      const taxeVente = produitForm.getFieldValue("tvaVente");
      const taxeAchat = produitForm.getFieldValue("tvaAchat");
  
      const fournisseurId = produitForm.getFieldValue("fournisseurId");
      const produit_ventes = {
        tva: taxeVente,
        unite: data.uniteVente,
        prixTTC: prixVente.ttc,
        prixHT: prixVente.ht,
      };
      data.produit_ventes = produit_ventes;
  
      const produit_achats = {
        tva: taxeAchat,
        unite: data.uniteAchat,
        prixTTC: prixAchat.ttc,
        prixHT: prixAchat.ht,
        fournisseurId: fournisseurId,
      };
      data.produit_achats = produit_achats;
      data.type = modalType;
  
      const response = editMode
        ? await ProduitService.updateProduit(updateId, data)
        : await ProduitService.addProduit(data);
      if (response.status === 200 || 204) {
        if (editMode) message.info("Mise à jour avec success");
        else message.info("Produit ajouter avec success");
      } else message.error("Une erreur est survenu ! ");
      closingModal();
    };

    const showProduitModal = (type) => {
      setModalType(type);
      console.log("inside showProduitModal");
  
      if (type === 0) setModalTitle("Produit");
      else if (type === 1) setModalTitle("Service");
      else setModalTitle("Produit Composé");
  
      const composition_produits =
        produitForm.getFieldValue("composition_produits") ?? [];
      if (composition_produits.length === 0 && type === 2) {
        const placeholderData = {
          composed_from: null,
          prixUnitaireHT: null,
          Quantité: null,
          TVA: null,
          prixTotalTTC: null,
        };
        composition_produits.push(placeholderData, placeholderData);
      }
  
      produitForm.setFieldsValue({ composition_produits: composition_produits });
      setOpenModal(true);
    };

    const items = [
      {
        label: "Ajouter Un Produit",
        className: "px-5",
        key: "1",
        onClick: () => showProduitModal(0),
      },
      {
        label: "Ajouter Un Service",
        className: "px-5",
        key: "2",
        onClick: () => showProduitModal(1),
      },
      {
        label: "Ajouter Un Produit avec embalage",
        className: "px-5",
        key: "3",
        onClick: () => showProduitModal(2),
      },
    ];
  
    return (
      <>
      {Can("19_2") ? (
        <Spin  className="px-5" spinning={isLoading} size="large" >
          <Card className="w-100 card-shadow">
            <Form layout="vertical" initialValues={{ devise: "TND", }} form={productsForm} onFinish={addFactureVente}>
              <Row>
                <FicheHeader
                  moduleName={"Facture "}
                  showClients={isVente}
                  entreprise={entreprise}
                  clients={clients}
                  fournisseurs={fournisseurs}
                  selectedClient={() => clientChanged()}
                  reloadClients={() => reloadClients()}

                ></FicheHeader>
              </Row>
              <Row>
                {isVente ? (
                  <Col span={8} className="px-2 pt-3">
                    <FicheClient client={client}></FicheClient>
                  </Col>
                ) : (
                  <Col span={8} className="px-2 pt-3">
                    <FicheFournisseur fournisseur={fournisseur}></FicheFournisseur>
                  </Col>
                )}
                <Col span={8} offset={8} className="px-2 pt-3">
                  <FicheDetails
                    currencyChanged={ ()=>  changeCurrency()}
                  ></FicheDetails>
                </Col>
              </Row>
              <Row>
                  <Col span="24" className="px-4">
                    <table
                      className={` mb-5 table ${
                        isDarkMode ? "table-dark" : ""
                      } `}
                    >
                      <thead className="pl-2">
                        <Row>
                          <Col span={1}>
                            <b>#</b>
                          </Col>
                          <Col span={6}>
                            <b>Libelle</b>
                          </Col>


                          <Col span={2}>
                            <b>Quantité</b>
                          </Col>

                          <Col span={2}>
                            <Tooltip title="Quantité Gratuite">
                              <b className="pl-3">QG</b>
                            </Tooltip>
                          </Col>

                          <Col span={1}>
                            <Tooltip title="Quantité Total">
                              <b>QT</b>
                            </Tooltip>
                          </Col>

                          <Col span={2}>
                            <Tooltip title="Prix Unitaire HT">
                              <b>Prix HT</b>
                            </Tooltip>
                          </Col>

                          <Col span={2}>
                            <b>TVA</b>
                          </Col>

                          <Col span={2}>
                            <b>Remise</b>
                          </Col>

                          <Col span={2}>
                            <b>Total HT</b>
                          </Col>

                          <Col span={2}>
                            <b>Total TTC</b>
                          </Col>

                          <Col className="px-3" span={2}>
                            <Tooltip title="Ajouter un produit">
                              <Button
                                shape="circle"
                                disabled={!client}
                                icon={<PlusCircleOutlined />}
                                onClick={() => setProductsModal(true)}
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      </thead>
                      <tbody>
                        <Form.List name="produit_facture_ventes" label="">
                          {(fields, { add, remove }) => (
                            <>
                              <Row className="pb-1 mb-1 ">
                                {fields.map(
                                  ({ key, name, ...restField }, index) => (
                                    <>
                                      <Col className="mb-0 pb-0" span={1}>
                                        {index + 1}
                                      </Col>
                                      <Col className="mb-0 pb-0" span={6}>
                                        <Text className="mb-0 pb-0">
                                          {getLibelle(index)}
                                        </Text>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "libelle"]}
                                          style={{ height: 0 }}
                                        >
                                          <Input
                                            type="hidden"
                                            bordered={false}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantite"]}
                                        >
                                          <InputNumber
                                            style={{ width: 60 }}
                                            onChange={() => priceChanged(index)}
                                            className="w-125px"
                                            min={getProduitMin(index)}
                                            max={getProduitMax(index)}

                                          />
                                        </Form.Item>
                                      </Col>

                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantiteGratuite"]}
                                        >     
                                          <InputNumber
                                              style={{ width: 60 }}
                                              onChange={() => priceChanged(index)}
                                              className="w-125px"
                                              min={0}
                                            />
                                        </Form.Item>
                                      </Col>

                                      <Col className="mb-0 pb-0" span={1}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "quantiteTotal"]}
                                        >
                                          <InputNumber
                                            className={`${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 50 }}
                                          />
                                        </Form.Item>
                                      </Col>

                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "prixHT"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "tva"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            style={{ width: 60 }}
                                            min={0}
                                            max={100}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) =>
                                              value.replace("%", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "remise"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            onChange={() => priceChanged(index)}
                                            min={0}
                                            max={100}
                                            style={{ width: 60 }}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) =>
                                              value.replace("%", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "total_prix_HT"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            className={` ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0" span={2}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "total_prix_TTC"]}
                                        >
                                          <InputNumber
                                            className={`w-125px ${
                                              isDarkMode
                                                ? "total-input-dark"
                                                : "total-input-light"
                                            } `}
                                            disabled
                                            bordered={false}
                                            style={{ width: 120 }}
                                            min={0}
                                            formatter={(value) =>
                                              `${value}`
                                            }
                                            parser={(value) =>
                                              value.replace("", "")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col className="mb-0 pb-0 px-3" span={2}>
                                        <Tooltip title="Supprimer le produit">
                                          <Button
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                              removeElement(
                                                getProductId(index)
                                              );
                                              remove(name);
                                              calculeTotalTaxes();
                                            }}
                                          />
                                        </Tooltip>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            </>
                          )}
                        </Form.List>
                      </tbody>
                    </table>
                  </Col>
                  <Col span={8} offset={16} className="px-4">
                    <table
                      className={`table ${isDarkMode ? "table-dark" : ""} `}
                    >
                      <thead>
                        <tr>
                          <th scope="col" className="pb-3">
                            Remise :{" "}
                          </th>
                          <th scope="col">
                            <Form.Item
                              name="remise_global"
                              className="my-0 py-1"
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace("%", "")}
                                min={0}
                                disabled
                                max={100}
                              />
                            </Form.Item>
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" className="pb-3">
                            Acompte :
                          </th>
                          <th scope="col">
                            <Form.Item
                              name="acompte_pourcentage"
                              className="my-0 py-1"
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace("%", "")}
                                min={0}
                                disabled
                                max={100}
                              />
                            </Form.Item>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                  <FicheTableGain
                   selectedCurrency={selectedCurrency}
                    gains={gains}
                    isDarkMode={isDarkMode}
                  ></FicheTableGain>
                  <FicheTableTva
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    appliedTaxes={appliedTaxes}
                  ></FicheTableTva>
                  <FicheTableTotauxFactureVente
                    fodec={fodec}
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    prixTotal={prixTotal}
                  ></FicheTableTotauxFactureVente>
                </Row>
              <Divider className="text-capitalize">{writtenTotal}</Divider>

    
              <FicheFooter entreprise={entreprise}></FicheFooter>
    
              <Row className="d-flex flex-row-reverse pt-5 pb-2">
                <Button 
                  className="mx-2" 
                  type="primary"
                  onClick={productsForm.submit}
                  disabled={disableForm()}

                >
                  Enregistrer
                </Button>
                
              </Row>
            </Form>
          </Card>
    
          <Modal
            visible={productsModal}
            getContainer={false}
            centered
            footer={null}
            onCancel={handleCancel}
            title={
            <div className="d-flex justify-content-between px-4">
              <div> Liste des Produits </div>
              <div className="mr-5 pr-5">
                <Dropdown.Button  type="primary" menu={{items}}>
                  Ajouter Un Produit
                </Dropdown.Button>
              </div>
            </div>}
            width={1000}
          >
            <Spin spinning={isLoading} size="large">
              <TableWithFiltres data={productList} columns={ProduitColumns} />
            </Spin>
          </Modal>

          {/* begin add product modal */}
          <Modal
              visible={openModal}
              onOk={produitForm.submit}
              onCancel={handleCancelAddProduct}
              getContainer={false}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={1100}
              title={editMode ? "Modifier le " : "Ajouter un " + modalTitle}
            >
              <Form layout="vertical" form={produitForm} onFinish={addProduit}>
                <Tabs>
                  <Tabs.TabPane tab={"Informations"} key="1">
                    <Form.Item className="hideen" name="type">
                      <Input type="text" />
                    </Form.Item>
                    <Row>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="libelle"
                          name="libelle"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Row>
                          <Col span={20} className="pl-2">
                            <Form.Item
                              label="Catégorie"
                              name="categorieId"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                },
                              ]}
                            >
                              <Select size="large">
                                {categorie.map((c) => (
                                  <Select.Option key={c.id} value={c.id}>
                                    {c.nom}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4} className="pr-2">
                            <Form.Item label=" ">
                              <Tooltip title="Ajouter une categorie">
                                <Button
                                  className="margin-05"
                                  type=""
                                  onClick={() => openAddCategorieModal()}
                                  icon={<PlusOutlined />}
                                  size={"large"}
                                />
                              </Tooltip>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Référence Interne"
                          name="reference_interne"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Code barre"
                          name="code"
                          rules={[
                            {
                              message: "Maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Row>
                          <Col span={20} className="pl-2">
                            <Form.Item
                              label="Marque"
                              name="marqueId"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <Select size="large">
                                {marque.map((m) => (
                                  <Select.Option key={m.id} value={m.id}>
                                    {m.nom}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4} className="pr-2">
                            <Form.Item label=" ">
                              <Tooltip title="Ajouter une marque">
                                <Button
                                  className="margin-05"
                                  onClick={() => openAddMarqueModal()}
                                  type=""
                                  icon={<PlusOutlined />}
                                  size={"large"}
                                />
                              </Tooltip>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Référence constructeur"
                          name="reference_constructeur"
                          rules={[
                            {
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                    </Row>
                    {modalType !== 2 ? (
                      <div>
                        <Row>
                          <Col span={24} className="p-2">
                            {modalType === 1 ? (
                              <b> Coût du service : </b>
                            ) : (
                              <b> Prix Achat : </b>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} className="px-2">
                            <Row>
                              <Col span={21} className="pl-2">
                                <Form.Item
                                  label="Tva"
                                  name="tvaAchat"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Champ obligatoire !",
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    onChange={() => calculateAchat()}
                                  >
                                    {tva.map((t) => (
                                      <Select.Option
                                        key={t.id}
                                        value={t.valeur}
                                      >
                                        {t.titre} - {t.valeur} %
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={3} className="pr-2">
                                <Form.Item label=" ">
                                  <Tooltip title="Ajouter une TVA ">
                                    <Button
                                      className="margin-05"
                                      onClick={() => openAddTvaModal()}
                                      type=""
                                      icon={<PlusOutlined />}
                                      size={"large"}
                                    />
                                  </Tooltip>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label="Unité"
                              name="uniteAchat"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                onChange={() => calculateAchat()}
                              >
                                {unite.map((u) => (
                                  <Select.Option key={u.id} value={u.id}>
                                    {u.code_fr}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label={
                                modalTitle === "Produit Simple" ? (
                                  <> Prix Fournisseur </>
                                ) : (
                                  <> Coût</>
                                )
                              }
                              name="prixFournisseur"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Champ obligatoire avec maximum de caractère 100 !",
                                },
                              ]}
                            >
                              <InputNumber
                                onChange={() => calculateAchat()}
                                size="large"
                                style={{ width: "100%" }}
                                min={0}
                                max={99999999999}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6} className="px-2">
                            <Form.Item
                              label="Type"
                              name="typePrix"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire ",
                                },
                              ]}
                            >
                              <Radio.Group onChange={() => calculateAchat()}>
                                <Radio value={"TTC"}>TTC</Radio>
                                <Radio value={"HT"}>HT</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span={6} className="px-2">
                            <Form.Item label="Résultat:">
                              Prix HT : {prixAchat.ht}
                              <br />
                              Prix TTC : {prixAchat.ttc}
                              <br />
                              <Input type={"hidden"}></Input>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Tabs.TabPane>
                  {modalType === 2 && (
                    <Tabs.TabPane
                      tab={"Composition "}
                      key="2"
                      forceRender={true}
                    >
                      <Row>
                        <Col span={24}>
                          <b className="p-2">Composition :</b>
                        </Col>

                        <Form.List
                          name="composition_produits"
                          label="Liste des produits"
                        >
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <>
                                  <Col span={9} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "composed_from"]}
                                      label={key === 0 ? "Produit" : "Embalage"}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <Select
                                        size="large"
                                        onChange={() => productChanged(key)}
                                      >
                                        {dataTable
                                          .filter((e) =>
                                            key === 0
                                              ? e.categorieId !== 2
                                              : e.categorieId === 2
                                          )
                                          .map((prod) => (
                                            <Select.Option
                                              key={prod.id}
                                              value={prod.id}
                                            >
                                              {prod.showType} - {prod.libelle}
                                            </Select.Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "prixUnitaireHT"]}
                                      label="Prix Unitaire HT"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        onChange={() => prixChanged(key)}
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={10000000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "qte"]}
                                      label="Quantité"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        onChange={() => prixChanged(key)}
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "tva"]}
                                      label="TVA"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "prixTotalHT"]}
                                      label="Prix Total HT"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={1000}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} className="px-2 mt-2">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "prixTotalTTC"]}
                                      label="Prix Total TTC"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Champ obligatoire!",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled
                                        size="large"
                                        style={{ width: "100%" }}
                                        min={0}
                                        max={100000000}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </Row>
                      <Col span={22} className="px-2">
                        <div className="d-flex flex-row-reverse">
                          <Form.Item label="Résultat:">
                            Prix HT : {prixAchat.ht}
                            <br />
                            Prix TTC : {prixAchat.ttc}
                            <br />
                          </Form.Item>
                        </div>
                      </Col>
                      <div className="hideen">
                        <Form.Item name="tvaAchat">
                          <Input type="text" />
                        </Form.Item>
                      </div>
                    </Tabs.TabPane>
                  )}
                  <Tabs.TabPane tab={"Prix vente"} key="3" forceRender={true}>
                    <Row>
                      <Col span={12} className="px-2 my-2">
                        <b className="px-2">Prix Vente :</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16} className="px-2">
                        <Row>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label="Marge HT"
                              name="marge_ht"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                style={{ width: "100%" }}
                                min={0}
                                max={99999999999}
                                onChange={() => margeChanged()}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12} className="px-2">
                            <Form.Item
                              label="Prix Vente HT"
                              name="prixVente"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                style={{ width: "100%" }}
                                min={0}
                                max={99999999999}
                                onChange={() => prixVenteChanged()}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="px-4">
                        <Form.Item label="Résultat:">
                          Prix HT : {prixVente.ht} <br />
                          Prix TTC : {prixVente.ttc} <br />
                          <Input type={"hidden"}></Input>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Type"
                          name="VentetypePrix"
                          style={{ visibility: "hidden" }}
                        >
                          <Radio.Group>
                            <Radio value={"TTC"}>TTC</Radio>
                            <Radio value={"HT"}>HT</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Tva"
                          name="tvaVente"
                          style={{ visibility: "hidden" }}
                        >
                          <Select size="large" disabled>
                            {tva.map((t) => (
                              <Select.Option key={t.id} value={t.valeur}>
                                {t.titre} - {t.valeur} %
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Unité"
                          name="uniteVente"
                          style={{ visibility: "hidden" }}
                        >
                          <Select
                            className="w-100"
                            size="large"
                            disabled
                            onChange={() => calculateAchat()}
                          >
                            {unite.map((u) => (
                              <Select.Option
                                key={u.id}
                                value={u.id}
                                className="w-100"
                              >
                                {u.code_fr}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={"Description"} key="4" forceRender={true}>
                    <Row>
                      <Col span={12} className="px-2">
                        <Form.Item
                          name="proprietere"
                          label="Votre entreprise est Propriétaire?"
                          rules={[
                            { required: true, message: "Champ obligatoire !" },
                          ]}
                        >
                          <Radio.Group onChange={() => setProprietereValue()}>
                            <Radio value={false}>Non</Radio>
                            <Radio value={true}>Oui</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        {!proprietereVal && (
                          <Col span={24} className="px-2">
                            <Row>
                              <Col span={21} className="pl-2">
                                <Form.Item
                                  label="Fournisseur"
                                  name="fournisseurId"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Champ obligatoire !",
                                    },
                                  ]}
                                >
                                  <Select size="large" className="w-100">
                                    {fournisseur.map((f) => (
                                      <Select.Option key={f.id} value={f.id}>
                                        {f.nom} {f.prenom}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={3} className="pr-2">
                                <Form.Item label=" ">
                                  <Tooltip title="Ajouter un fournisseur">
                                    <Button
                                      className="margin-05"
                                      onClick={() => openAddFournisseurModal()}
                                      type=""
                                      icon={<PlusOutlined />}
                                      size={"large"}
                                    />
                                  </Tooltip>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="px-2">
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              message:
                                "Champ obligatoire avec maximum de caractère 255 !",
                              max: 254,
                            },
                          ]}
                        >
                          <TextArea type="text" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={"Caracteristique de vente"}
                    key="5"
                    forceRender={true}
                  >
                    <Row>
                      <Col span={12} className="px-2 mt-2">
                        <Form.Item
                          name="groupesClients"
                          label="Le produit peut etre vendu aux groupes "
                        >
                          <Select mode="multiple" allowClear size="large">
                            {groupesClients.map((c) => (
                              <Select.Option key={c.id} value={c.id}>
                                {c.nom}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-3">
                        <Form.Item
                          label="Paiement direct uniquement"
                          name="typeVente"
                        >
                          <Radio.Group>
                            <Radio value={false}>Non</Radio>
                            <Radio value={true}>Oui</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Quantité Minium à la vente"
                          name="qteMinVente"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Quantité Maximum à la vente"
                          name="qteMaxVente"
                          rules={[
                            {
                              required: true,
                              message: "Champ obligatoire !",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: "100%" }}
                            min={0}
                            max={99999999999}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2 mt-2">
                        <Form.Item
                          name="formuleGratuiteId"
                          label="Formule de gratuité"
                          rules={[
                            { required: true, message: "Champ obligatoire !" },
                          ]}
                        >
                          <Select
                            allowClear
                            size="large"
                            onChange={() => formuleSelectChanged()}
                          >
                            {formuleGratuite.map((c) => (
                              <Select.Option key={c.id} value={c.id}>
                                {c.nom}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {showDates && (
                        <>
                          <Col span={6} className="px-2 mt-2">
                            <Form.Item
                              label="Date debut"
                              name="applicationFormuleDebut"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder=""
                                format={""}
                                size="large"
                                className="w-100"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={6} className="px-2 mt-2">
                            <Form.Item
                              label="Date fin"
                              name="applicationFormuleFin"
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder=""
                                format={""}
                                size="large"
                                className="w-100"
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Form>
            </Modal>
          {/* end add product modal */}
          <AddTvaModalForm
              showModal={tvaModal}
              tvaAdded={(action) => onTvaModalClose(action)}
            ></AddTvaModalForm>
            <AddMarqueModalForm
              showModal={marqueModal}
              marqueAdded={(action) => onMarqueModalClose(action)}
            ></AddMarqueModalForm>
            <AddCategorieModalForm
              showModal={categorieModal}
              categorieAdded={(action) => onCategorieModalClose(action)}
            ></AddCategorieModalForm>
            <AddFournisseurModalForm
              showModal={fournisseurModal}
              fournisseurAdded={(action) => onFournisseurModalClose(action)}
            ></AddFournisseurModalForm>

        </Spin>      
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
    );
  }
  
  export default FactureSimple;
  