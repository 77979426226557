import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import AppTable from "../../../uiHelpers/AppTabe";
import CompteBancaireService from "../../../../services/compteBancaire.service";
import banks from "../../../../helpers/banks";
import Can from "../../../../security/Can";

const CompteBancaire = () => {
  const [compteBancaireForm] = Form.useForm();
  const [compteBancaireModal, setCompteBancaireModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [switchValue, setSwitchValue] = useState("unchecked");

  useEffect(() => {
    loadCompteBancaires();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadCompteBancaires = async () => {
    setLoading(true);
    const response = await CompteBancaireService.getCompteBancaires();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const initTableData = (data) => {
    for (const element of data) {
      element.showBanque = banks.find(bank => bank.id === element.banque).code_fr ;
    }
    setDataTable(data);
  };

  const showCompteBancaireModal = () => {
    setCompteBancaireModal(true);
  };

  const handleOk = () => {
    setCompteBancaireModal(false);
  };

  const updateMode = (record) => {
    setCompteBancaireModal(true);
    const checkedSwitch = record.etat ? "checked" : "unchecked";
    setSwitchValue(checkedSwitch);
    compteBancaireForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handleCancel = () => {
    setCompteBancaireModal(false);
    clearData();
  };

  const addCompteBancaire = async (values) => {
    setLoading(true);
    var data = values;
    data.etat = data.etat ? data.etat : false;
    data.type = 2;
    const response = editMode
      ? await CompteBancaireService.updateCompteBancaire(updateId, data)
      : await CompteBancaireService.addCompteBancaire(data);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Compte Bancaire ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();
  };

  const closingModal = () => {
    loadCompteBancaires();
    setCompteBancaireModal(false);
    setLoading(false);
    clearData();
  };

  const clearData = () => {
    compteBancaireForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const CompteBancaireColumns = [
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.localeCompare(b.designation),
      width: "10%",
    },
    {
      title: "Banque",
      dataIndex: "showBanque",
      width: "20%",
      sorter: (a, b) => a.showBanque.localeCompare(b.showBanque),
    },
    {
      title: "Agence",
      dataIndex: "agence",
      width: "20%",
      sorter: (a, b) => a.agence.localeCompare(b.agence),
    },
    {
      title: "SWIF",
      dataIndex: "swift",
      width: "15%",
      sorter: (a, b) => a.swift.localeCompare(b.swift),
    },
    {
      title: "IBAN",
      dataIndex: "iban",
      width: "15%",
      sorter: (a, b) => a.iban.localeCompare(b.iban),
    },
    
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Tag color={record.etat ? "cyan" : "red"}>
          {record.etat ? "Activé" : "Désactivé"}
        </Tag>
      ),
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("4_3")}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("24_7")}
              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce Compte Bancaire ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const deleteItem = async (item) => {
    const response = await CompteBancaireService.deleteCompteBancaire(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression avec success");
      loadCompteBancaires()
    } else message.error("Une erreur est survenu ! ");
  };


  return (
    <Spin spinning={isLoading} size="large">
      <Card
        
        title={"Compte Bancaire"}
        extra={
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              disabled={!Can("4_2")}
              onClick={() => showCompteBancaireModal()}
            >
              Ajouter un Compte Bancaire
            </Button>
        }
      >
        <AppTable data={dataTable} columns={CompteBancaireColumns} />
        <Form
          layout="vertical"
          form={compteBancaireForm}
          onFinish={addCompteBancaire}
        >
          <Modal
            visible={compteBancaireModal}
            onOk={compteBancaireForm.submit}
            onCancel={handleCancel}
            getContainer={false}
            okText="Enregistrer"
            cancelText="Annuler"
            centered
            width={750}
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">
                    {editMode
                      ? "Modifier le compte bancaire"
                      : "Ajouter un compte bancaire"}
                  </div>
                  <div>
                    <Form.Item
                      className="my-0 mx-5"
                      name="etat"
                      valuePropName={switchValue}
                    >
                      <Switch
                        checkedChildren="Activer"
                        unCheckedChildren="Désactiver"
                        className="px-4"
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            }
          >
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Désignation"
                  name="designation"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Banque"
                  name="banque"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                    },
                  ]}
                >
                  <Select size="large">
                    {banks.map((bank) => (
                      <Select.Option key={bank.id} value={bank.id}>
                        {bank.code_fr}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Agence"
                  name="agence"
                  rules={[
                    {
                      message:
                        "Champ obligatoire avec maximum de caractère 255 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="swift"
                  name="swift"
                  rules={[
                    {
                      message:
                        "Champ obligatoire avec maximum de caractère 255 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="iban"
                  name="iban"
                  rules={[
                    {
                      message:
                        "Champ obligatoire avec maximum de caractère 255 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      message:
                        "Champ obligatoire avec maximum de caractère 255 !",
                      max: 99,
                    },
                  ]}
                >
                  <TextArea rows={4} type="text" maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </Form>
      </Card>
    </Spin>
  );
};
export default CompteBancaire;
