import {
  EditOutlined,
  DeleteOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import Can from "../../../security/Can";
import StockService from "../../../services/Stock.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";

const Stock = () => {
  const [stockForm] = Form.useForm();
  const [storeForm] = Form.useForm();
  const [transferForm] = Form.useForm();
  const [updateModal, setUpdateModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [storeId, setStoreId] = useState();
  const [stores, setStores] = useState([]);
  const [storeModal, setStoreModal] = useState(false);
  const [storesModal, setStoresModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [productId, setProductId] = useState(null);
  const [max, setMax] = useState(0);

  useEffect(() => {
    getStoreNames();
    getStock(1);
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const getStoreNames = async () => {
    setLoading(true);
    const response = await StockService.getStores();
    if (response.status === 200 || 204) setStores(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const getStock = async (id) => {
    setLoading(true);
    const response = await StockService.getQuantite(id);
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const initTableData = (data) => {
    for (const element of data) {
      element.libelle = element.produit.libelle;
      element.reference_interne = element.produit.reference_interne;
    }
    setDataTable(data);
  };

  const showEmployeModal = () => {
    setUpdateModal(true);
  };

  const updateMode = (record) => {
    setUpdateModal(true);
    stockForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const updateStoreMode = (record) => {
    storeForm.setFieldsValue(record);
    setEditMode(true);
    setUpdateId(record.id);
    setStoreModal(true);
  };

  const handleCancel = () => {
    setUpdateModal(false);
    clearData();
  };

  const handleCancelStore = () => {
    setStoreModal(false);
    setEditMode(false);
    setUpdateId(null);
    storeForm.resetFields();
  };

  const updateStock = async (values) => {
    setLoading(true);
    const response = await StockService.update(updateId, values);
    if (response.status === 200 || 204)
      message.info("Mise à jour avec success");
    else message.error("Une erreur est survenu ! ");
    closingModal();
  };

  const closingModal = () => {
    getStock(1);
    setUpdateModal(false);
    setLoading(false);
    clearData();
  };

  const clearData = () => {
    stockForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const addStore = async (values) => {
    setLoading(true);
    const response = editMode
      ? await StockService.updateStore(updateId, values)
      : await StockService.addStore(values);
    if (response.status === 200 || 204)
      if (editMode) message.info("Store mise à jour avec success");
      else message.info("Store ajouter avec success");
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
    storeForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
    setStoreModal(false);
    await getStoreNames();
  };

  const deleteStore = async (id) => {
    setLoading(true);
    const response = await StockService.deleteStore(id);
    if (response.status === 200 || 204)
      message.info("Store supprimer avec success");
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
    await getStoreNames();
  };

  const storeChanged = async (id) => {
    await getStock(id);
  };

  const openTransferModal = (record) => {
    setMax(record.quantite);
    setSelectedName(record.libelle);
    setStoreId(record.storeId);
    setProductId(record.produitId);
    const du = record.storeId;
    const quantite = 1;
    transferForm.setFieldsValue({ du, quantite });
    setTransferModal(true);
  };

  const transferProduct = async (values) => {
    setLoading(true);
    const response = await StockService.updateStoreProducts(productId, values);
    if (response.status === 200 || 204)
      message.info("Produit Transférer avec success");
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
    setTransferModal(false);
    transferForm.resetFields();
    getStock(1);
  };

  const stockCols = [
    {
      title: "Produit libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Référence interne",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Quantité",
      dataIndex: "quantite",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              disabled={!Can("20_3")}
              shape="circle"
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Transférer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => openTransferModal(record)}
              icon={<ShareAltOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const storeCols = [
    {
      title: "Nom du store",
      dataIndex: "nom",
      width: "25%",

      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "60%",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",

      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("27_3")}
              onClick={() => updateStoreMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              disabled={record.id === 1 || !Can("27_7")}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => deleteStore(record.id)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {Can("20_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Stock"}
            extra={
              <>
                <Select
                  defaultValue={1}
                  style={{ width: 300 }}
                  onChange={(value) => storeChanged(value)}
                >
                  {stores &&
                    stores.map((store) => (
                      <Select.Option value={store.id}>
                        {store.nom}
                      </Select.Option>
                    ))}
                </Select>
                <Button
                  className="mx-2"
                  type="primary"
                  onClick={() => setStoresModal(true)}
                  disabled={!Can("27_1")}
                >
                  Gestion des stores
                </Button>
              </>
            }
          >
            <TableWithFiltres size={50} data={dataTable} columns={stockCols} />
            <Form layout="vertical" form={stockForm} onFinish={updateStock}>
              <Modal
                visible={updateModal}
                onOk={stockForm.submit}
                onCancel={handleCancel}
                getContainer={false}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={900}
                title={"Modifier la quantité "}
              >
                <Row>
                  <Col span={8} className="px-2">
                    <Form.Item label="Libelle" name="libelle">
                      <Input
                        disabled
                        size="large"
                        type="text"
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Référence Interne"
                      name="reference_interne"
                    >
                      <Input
                        disabled
                        size="large"
                        type="text"
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Quantité"
                      name="quantite"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire!",
                        },
                      ]}
                    >
                      <Input size="large" type="text" />
                    </Form.Item>
                  </Col>
                </Row>
              </Modal>
            </Form>

            <Modal
              visible={storesModal}
              footer={null}
              onCancel={() => setStoresModal(false)}
              getContainer={false}
              centered
              width={800}
              title={
                <>
                  <div className="d-flex justify-content-between ">
                    <div className="my-0">Liste des stores</div>
                    <div>
                      <Button
                        className="mx-4"
                        type="primary"
                        onClick={() => setStoreModal(true)}
                        disabled={!Can("27_2")}
                      >
                        Ajouter
                      </Button>
                    </div>
                  </div>
                </>
              }
            >
              <TableWithFiltres data={stores} columns={storeCols} />
            </Modal>

            <Modal
              visible={storeModal}
              onOk={storeForm.submit}
              onCancel={handleCancelStore}
              getContainer={false}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={600}
              title={editMode ? "Mettre à jour" : "Ajouter un store"}
            >
              <Form layout="vertical" form={storeForm} onFinish={addStore}>
                <Row>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Nom du store"
                      name="nom"
                      rules={[
                        {
                          required: true,
                          message:
                            "Champ obligatoire avec maximum de caractère 100 !",
                          max: 99,
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          message:
                            "Champ obligatoire avec maximum de caractère 250 !",
                          max: 250,
                        },
                      ]}
                    >
                      <TextArea type="text" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>

            <Modal
              visible={transferModal}
              onOk={transferForm.submit}
              onCancel={() => setTransferModal(false)}
              getContainer={false}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={800}
              title={"Transférer du produit : " + selectedName}
            >
              <Form
                layout="vertical"
                form={transferForm}
                onFinish={transferProduct}
              >
                <Row>
                  <Col span={8} className="px-2">
                    <Form.Item label="Du store" name="du">
                      <Select disabled size="large">
                        {stores &&
                          stores.map((store) => (
                            <Select.Option value={store.id}>
                              {store.nom}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Vers"
                      name="vers"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                      ]}
                    >
                      <Select size="large">
                        {stores &&
                          stores.map((store) => (
                            <Select.Option
                              disabled={store.id === storeId}
                              value={store.id}
                            >
                              {store.nom}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} className="px-2">
                    <Form.Item label="Quantité" name="quantite">
                      <InputNumber
                        max={max}
                        min={0}
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Card>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default Stock;
