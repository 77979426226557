import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Row, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import AddClientModalForm from "../../pages/General/AddClientModalForm";
import { environment } from "../../../env/config.env";
import AddFournisseurModalForm from "../../pages/General/AddFournisseurModalForm";
const FILES_URL = environment.serverUrl;



function FicheHeader({
  moduleName,
  entreprise,
  clients,
  fournisseurs,
  selectedClient,
  selectedFournisseur,
  showClients,
  reloadFournisseur,
  reloadClients
}) {
  const [fournisseurModal, setFournisseurModal] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    setLogo(FILES_URL+"Files/Entreprise/"+entreprise[0]?.image);
    console.log(moduleName);
  }, [entreprise,fournisseurs,clients]);


  const clientChange = () => {
      selectedClient();
  };

  const fournisseurChange = () => {
    selectedFournisseur();
  };



  const checkVenteModuleName = () => {
    return ["Bon De Commande", "Facture","Bon De Livraison"].includes(moduleName);
  }
  

  const checkAchatModuleName = () => {
    return ["Facture","Bon De Récéption"].includes(moduleName);
  }

  const openAddFournisseurModal = () => {
    setFournisseurModal(true);
  };
  const onFournisseurModalClose = async (action) => {
    if (action) reloadFournisseur()
    setFournisseurModal(false);
  };

  const openAddClientModal = () => {
    setClientModal(true);
  };
  const onClientModalClose = async (action) => {
    if (action) reloadClients()
    setClientModal(false);
  };

  

  return (
    <>
      <Col span={20}>
        <Row>
          <Col span={8} className="text-center px-2 py-3 mt-4">
            <Image src={logo} preview={false} width={"75%"} height={"100px"} style={{maxWidth:"100px"}} />
          </Col>
          <Col span={16}>
            <div className="px-3 mt-3 ligne-height-25">
              <span>
                <strong className="description-text">
                  {" "}
                  {entreprise[0]?.raison_social}{" "}
                </strong>{" "}
              </span>{" "}
              <br />
              <span>
                <strong> MF : </strong>
              </span>
              <span className="description-text">
                {" "}
                {entreprise[0]?.matricule_fiscal}
              </span>{" "}
              <br />
              <span>
                <strong> Téléphone  :</strong>{" "}
              </span>
              <span className="description-text"> {entreprise[0]?.telephone}</span>{" "}
              <br />
              <span>
                <strong> Adresse :</strong>
              </span>
              <span className="description-text"> {entreprise[0]?.adresse} </span>{" "}
              <br />
              <span>
                <strong> E-mail :</strong>{" "}
              </span>
              <span className="description-text"> {entreprise[0]?.email} </span> <br />
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <div className="w-100 text-right">
          <h3>{moduleName}</h3>
        </div>
      </Col>
      <Col span={8} className="height-50">
        {showClients ? (

          <Col span={24} className="px-2">
            <Row>
              <Col span={21} className="pl-2">
                <Form.Item
                  label="Client :"
                  name="client"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire !",
                    },
                  ]}
                >
                  <Select
                    className="w-100 py-0"
                    size="large"
                    placeholder="Client"
                    disabled={checkVenteModuleName()}
                    onChange={() => clientChange()}
                  >
                    {clients.map((c) => (
                      <Select.Option key={c.id} value={c.id} className="w-100">
                        {c.nom} {c.nom} - {c.raison_sociale}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3} className="pr-2">
                <Form.Item label=" ">
                  <Tooltip title="Ajouter un client">
                    <Button
                      className="margin-05"
                      onClick={() => openAddClientModal()}
                      type=""
                      icon={<PlusOutlined />}
                      size={"large"}
                    />
                  </Tooltip>
                  <AddClientModalForm 
                    showModal={clientModal}
                    clientAdded={(action) => onClientModalClose(action)}  
                  ></AddClientModalForm>
                </Form.Item>
              </Col>
            </Row>
          </Col>

        ) : (
          <Col span={24} className="px-2">
            <Row>
              <Col span={21} className="pl-2">
                <Form.Item
                  name="fournisseur"
                  label="Fournisseur :"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire !",
                    },
                  ]}
                >
                  <Select
                    className="w-100"
                    size="large"
                    disabled={checkAchatModuleName()}
                    placeholder="Fournisseur :"
                    onChange={() => fournisseurChange()}
                  >
                    {fournisseurs.map((f) => (
                      <Select.Option key={f.id} value={f.id} className="w-100">
                        {f.nom} {f.nom} - {f.raison_sociale}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3} className="pr-2">
                <Form.Item label=" ">
                  <Tooltip title="Ajouter un fournisseur">
                    <Button
                      className="margin-05"
                      onClick={() => openAddFournisseurModal()}
                      type=""
                      icon={<PlusOutlined />}
                      size={"large"}
                    />
                  </Tooltip>
                  <AddFournisseurModalForm 
                    showModal={fournisseurModal}
                    fournisseurAdded={(action) => onFournisseurModalClose(action)}  
                  ></AddFournisseurModalForm>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
      </Col>
      <Col span={8} offset={8} className="px-2">
        <h4 className="pt-5">Détails document</h4>
      </Col>

      <Col span={8}>
        <div className="border"></div>
      </Col>
      <Col span={8} offset={8}>
        <div className="border"></div>
      </Col>
    </>
  );
}
export default FicheHeader;
